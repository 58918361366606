import cn from 'classnames';
import { memo, useRef } from 'react';

import { IconDragHorizontal } from '@assets/icons';

import { useTimelinePlaybackContext, useTimelinePlayheadContext } from '@context/TimelinePlaybackProvider';

import TimelineHeader from '@feature/studio/timeline/TimelineHeader';
import TimelinePlayheadLine from '@feature/studio/timeline/TimelinePlayheadLine';
import TimelineScale from '@feature/studio/timeline/TimelineScale';
import TimelineTracks from '@feature/studio/timeline/TimelineTracks';

function TimelineTimecode() {
  const { duration } = useTimelinePlaybackContext();
  const { timecode } = useTimelinePlayheadContext();

  return (
    <div className="timeline__timecode">
      <span>
        {timecode} / {duration}
      </span>
    </div>
  );
}

function WorkspaceTimeline({ templateLoading, height, getRef }) {
  const tracksElmRef = useRef(null);

  return (
    <div style={{ height }} className={cn('workspace__timeline', { loading: templateLoading })} data-tour="timeline">
      <div ref={getRef} className="timeline__resize-handle">
        <div className="timeline__resize-notch">
          <IconDragHorizontal />
        </div>
      </div>
      <div className="workspace__toolbar">
        <TimelineTimecode />
        <TimelineScale />
      </div>
      <div className="workspace__tracks" ref={tracksElmRef}>
        <TimelineHeader />
        <div id="tracks-container" className="tracks-container">
          <TimelinePlayheadLine />
          <TimelineTracks tracksElmRef={tracksElmRef} />
        </div>
      </div>
    </div>
  );
}

export default memo(WorkspaceTimeline);
