import { throttle } from 'lodash-es';
import { useEffect, useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';
import reactable from 'reactablejs';
import { useRecoilState, useRecoilValue } from 'recoil';

import { useTimelinePlaybackContext } from '@context/TimelinePlaybackProvider';

import { playheadAtom, timelineScaleAtom } from '@store/Timeline';

import { TIMELINE_TRACK_OFFSET } from '@constants/Timeline';

import getPixelSeconds from '@utils/getPixelSeconds';
import { isNumber } from '@utils/isNumber';

const draggableOptions = {
  inertia: false,
  autoScroll: true,
  axis: 'x',
};

const withPlayheadReactable = (WrappedComponent) => {
  const ReactableComponent = reactable(WrappedComponent);

  return function PlayheadReactable() {
    const [playhead, setPlayhead] = useRecoilState(playheadAtom);
    const { timelineDuration, isPlayable, isPlaying, handlePause } = useTimelinePlaybackContext();
    const [searchParams] = useSearchParams();
    const timelineScale = useRecoilValue(timelineScaleAtom);
    const pixelSeconds = getPixelSeconds(timelineScale);

    const trackLeftBound = useMemo(
      () => Math.max(TIMELINE_TRACK_OFFSET, pixelSeconds(playhead) + TIMELINE_TRACK_OFFSET),
      [pixelSeconds, playhead]
    );
    const x = useMemo(
      () => Math.min(trackLeftBound, pixelSeconds(timelineDuration) + TIMELINE_TRACK_OFFSET),
      [pixelSeconds, timelineDuration]
    );
    const throttledSetPlayhead = useMemo(() => throttle(setPlayhead, 25), [setPlayhead]);

    const handleDrag = (event) => {
      if (!event?.delta) return;

      if (isPlaying) handlePause();

      const deltaSeconds = event.delta.x / timelineScale;
      const newPlayheadPosition = Math.max(0, playhead + deltaSeconds);

      throttledSetPlayhead(newPlayheadPosition);
    };

    useEffect(() => {
      if (!searchParams.has('t')) return;

      const startInSeconds = parseFloat(searchParams.get('t'));
      if (!isNumber(startInSeconds) || startInSeconds < 0) return;

      setPlayhead(startInSeconds);
    }, [searchParams, setPlayhead]);

    if (!isPlayable || !timelineDuration) {
      return null;
    }

    return <ReactableComponent draggable={draggableOptions} onDragMove={handleDrag} x={x} />;
  };
};

export default withPlayheadReactable;
