import { Container, Graphics, Text } from '@inlet/react-pixi';
import { TextStyle } from 'pixi.js';
import { forwardRef, useEffect, useMemo, useRef, useState } from 'react';
import { useRecoilValue } from 'recoil';

import withCanvasMovable from '@feature/studio/canvas/CanvasMovable';

import ArtboardMask from '@components/masks/ArtboardMask';

import { playheadAtom } from '@store/Timeline';

import useFontCheck from '@hooks/useFontCheck';

import { CAPTION_COLOR, CAPTION_FONT_SIZE, COLOR, FONT_FAMILY, FONT_LINE_HEIGHT } from '@constants/TextAssetDefaults';

import formatWebGlColor from '@utils/formatWebGlColor';

const MIN_LINE_HEIGHT = 0.1;

const getOrientation = (aspectRatio) => {
  if (aspectRatio > 1) return 'landscape';
  if (aspectRatio < 1) return 'portrait';
  if (aspectRatio === 1) return 'square';
};

const CanvasCaptionPlayer = forwardRef((props, ref) => {
  const { clip, visible, x, y, width, height, alpha, scale, zIndex, angle, aspectRatio } = props;

  const textRef = useRef();
  const maskRef = useRef(null);

  const isFontLoaded = useFontCheck(clip['asset:font:family']);
  const playhead = useRecoilValue(playheadAtom);

  /** @type {ReturnType<typeof import('react').useState<{ [key: string]: any }>>} */
  const [currentText, setCurrentText] = useState('');
  const [currentCaptionIndex, setCurrentCaptionIndex] = useState(-1);
  const [textBounds, setTextBounds] = useState({ width: 0, height: 0 });

  const orientation = useMemo(() => getOrientation(aspectRatio), [aspectRatio]);

  const {
    ['asset:trim']: trim = 0,
    ['asset:font:color']: color = CAPTION_COLOR,
    ['asset:font:family']: fontFamily = FONT_FAMILY,
    ['asset:font:size']: fontSize = CAPTION_FONT_SIZE,
    ['asset:font:lineHeight']: lineHeight = FONT_LINE_HEIGHT,
    ['asset:font:opacity']: fontOpacity = 1,
    ['asset:background:color']: backgroundColor,
    ['asset:background:opacity']: backgroundOpacity = 1,
    ['asset:background:padding']: backgroundPadding = 0,
    ['asset:background:borderRadius']: backgroundBorderRadius = 0,
  } = clip;

  const { captions = [] } = clip['asset:meta'] || {};

  const clipStart = useMemo(() => {
    return clip?.start ? clip.start - (trim || 0) : 0;
  }, [clip?.start, trim]);

  const adjustedFontSize = useMemo(() => {
    return +fontSize + +fontSize * (orientation === 'landscape' ? 2 : 0.5);
  }, [fontSize, orientation]);

  const adjustedLineHeight = useMemo(() => {
    return lineHeight ? adjustedFontSize * lineHeight : adjustedFontSize * FONT_LINE_HEIGHT;
  }, [adjustedFontSize, lineHeight]);

  const adjustedBackgroundPadding = useMemo(() => {
    return backgroundPadding + 0.5 * backgroundPadding;
  }, [backgroundPadding]);

  const boundingBoxWidth = useMemo(() => {
    return textBounds.width + 2 * adjustedBackgroundPadding;
  }, [textBounds.width, adjustedBackgroundPadding]);

  const boundingBoxHeight = useMemo(() => {
    return textBounds.height + 2 * adjustedBackgroundPadding;
  }, [textBounds.height, adjustedBackgroundPadding]);

  const containerPosition = useMemo(() => {
    const multiplier = orientation === 'landscape' ? 1.3 : 1.4;
    return [0, height - (multiplier * height) / 2];
  }, [height, orientation]);

  const wordWrapWidth = useMemo(() => {
    const multiplier = orientation === 'landscape' ? 0.4 : 0.6;
    return width - (multiplier * width) / 2;
  }, [width, orientation]);

  const adjustedBackgroundBorderRadius = useMemo(() => {
    const multiplier = 4;
    return (backgroundBorderRadius || 0) * multiplier;
  }, [backgroundBorderRadius]);

  let bgColor = null;
  let bgOpacity = 0.0001; // if bgOpacity is 0, element is not interactive, can not be dragged.

  if (backgroundColor && backgroundColor !== 'transparent') {
    bgColor = formatWebGlColor(backgroundColor);
    bgOpacity = backgroundOpacity;
  }

  useEffect(() => {
    if (ref.current && maskRef.current) {
      ref.current.mask = maskRef.current;
    }
  }, [ref]);

  useEffect(() => {
    if (!captions || captions.length === 0) {
      setCurrentText('');
      setCurrentCaptionIndex(-1);
      return;
    }

    const newIndex = captions.findIndex(
      (caption) => playhead >= clipStart + caption.start && playhead < clipStart + caption.start + caption.length
    );

    if (newIndex !== currentCaptionIndex) {
      setCurrentCaptionIndex(newIndex);
      setCurrentText(newIndex !== -1 ? captions[newIndex].text : '');
    }
  }, [captions, playhead, currentCaptionIndex, clipStart]);

  useEffect(() => {
    if (textRef.current && currentText !== '') {
      const bounds = textRef.current.getLocalBounds();
      setTextBounds({ width: bounds.width, height: bounds.height });
    }
  }, [currentText, isFontLoaded, aspectRatio, fontSize, lineHeight, clipStart]);

  const boundingBox = (graphics) => {
    graphics.clear();
    graphics.beginFill(bgColor, bgOpacity);

    const x = -boundingBoxWidth / 2;
    const y = -adjustedBackgroundPadding;
    const radius = adjustedBackgroundBorderRadius;

    graphics.drawRoundedRect(x, y, boundingBoxWidth, boundingBoxHeight, radius);
    graphics.endFill();
  };

  const textStyle = new TextStyle({
    align: 'left',
    fontFamily: fontFamily || FONT_FAMILY,
    fontSize: `${adjustedFontSize || 1}px`,
    fill: color || COLOR,
    lineHeight: lineHeight === 0 ? MIN_LINE_HEIGHT : adjustedLineHeight,
    wordWrap: true,
    wordWrapWidth,
    textBaseline: 'alphabetic',
  });

  return (
    <>
      <Container
        ref={ref}
        anchor={0.5}
        visible={visible}
        x={x}
        y={y}
        width={width}
        height={height}
        alpha={alpha}
        scale={scale}
        zIndex={zIndex}
        angle={angle}
      >
        {currentText && isFontLoaded ? (
          <Container position={containerPosition}>
            <Graphics draw={boundingBox} />
            <Text
              ref={textRef}
              text={currentText}
              style={textStyle}
              alpha={fontOpacity}
              anchor={[0.5, 0]}
              x={0}
              y={0}
            />
          </Container>
        ) : null}
      </Container>
      <ArtboardMask ref={maskRef} />
    </>
  );
});

export default withCanvasMovable(CanvasCaptionPlayer);
