import AssetAudio from '@feature/studio/setting/SettingAssetAudio';
import AssetCaption from '@feature/studio/setting/SettingAssetCaption';
import AssetHtml from '@feature/studio/setting/SettingAssetHtml';
import AssetImage from '@feature/studio/setting/SettingAssetImage';
import AssetImageToVideo from '@feature/studio/setting/SettingAssetImageToVideo';
import AssetMask from '@feature/studio/setting/SettingAssetMask';
import AssetOverlay from '@feature/studio/setting/SettingAssetOverlay';
import AssetText from '@feature/studio/setting/SettingAssetText';
import AssetTextToImage from '@feature/studio/setting/SettingAssetTextToImage';
import AssetTextToSpeech from '@feature/studio/setting/SettingAssetTextToSpeech';
import AssetVideo from '@feature/studio/setting/SettingAssetVideo';

const SettingComponentMap = {
  audio: AssetAudio,
  html: AssetHtml,
  text: AssetText,
  video: AssetVideo,
  image: AssetImage,
  overlay: AssetOverlay,
  mask: AssetMask,
  caption: AssetCaption,
  'text-to-speech': AssetTextToSpeech,
  'text-to-image': AssetTextToImage,
  'image-to-video': AssetImageToVideo,
};

function Setting({ id, type }) {
  const SettingComponent = SettingComponentMap[type];
  if (!SettingComponent) {
    return null;
  }

  return <SettingComponent key={id} id={id} />;
}

export default Setting;
