import { Sound } from '@pixi/sound';
import { useCallback, useEffect, useState } from 'react';
import { useRecoilValue } from 'recoil';

import { clipSelectorFamily, clipVisibilitySelectorFamily } from '@store/EditSelectors';
import { playbackMuteAtom, playheadAtom, playheadIsPausedAtom, playheadIsStoppedAtom } from '@store/Timeline';

import roundToPrecision from '@utils/math/roundToPrecision';

function CanvasAudioPlayer({ id }) {
  const {
    start,
    ['asset:src']: src,
    ['asset:trim']: trim,
    ['asset:volume']: volume,
  } = useRecoilValue(clipSelectorFamily(id));
  const playhead = useRecoilValue(playheadAtom);
  const isPaused = useRecoilValue(playheadIsPausedAtom);
  const isStopped = useRecoilValue(playheadIsStoppedAtom);
  const isMuted = useRecoilValue(playbackMuteAtom);
  const [sound, setSound] = useState();
  const visible = useRecoilValue(clipVisibilitySelectorFamily(id));

  useEffect(() => {
    try {
      if (!src) {
        throw new Error('No audio source');
      }

      const soundSrc = new URL(src).href;
      const pixiSound = Sound.from({
        url: soundSrc,
        preload: true,
        singleInstance: true,
      });

      setSound(pixiSound);
    } catch (error) {
      console.error(error);
    }

    return () => {
      sound?.destroy();
      setSound();
    };
  }, [src]);

  const play = useCallback(
    ({ start: startTime }) => {
      if (!sound?.isLoaded) return;
      sound.play({
        start: startTime,
        volume: volume >= 0 && !isMuted ? volume : 0,
      });
    },
    [volume, isMuted, sound]
  );

  const stop = useCallback(() => {
    if (!sound?.isLoaded || !sound?.isPlaying || isMuted) return;
    sound.stop();
  }, [isMuted, sound]);

  useEffect(() => {
    try {
      if (sound?.isPlaying && (!visible || isPaused || isStopped)) {
        stop();
        return;
      }

      if (visible && !isPaused && !isStopped) {
        const soundStart = (trim || 0) + roundToPrecision(playhead - start);
        play({ start: soundStart });
      }
    } catch (error) {
      console.error(error);
    }
  }, [isMuted, isPaused, isStopped, visible]);

  return null;
}

export default CanvasAudioPlayer;
