import { useCallback } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useRecoilState } from 'recoil';

import { IconVoiceChat } from '@assets/icons';

import TimingFields from '@feature/studio/fields/TimingFields';

import InputSelect from '@components/controls/InputSelect';
import InputTextarea from '@components/controls/InputTextarea';

import { clipSelectorFamily } from '@store/EditSelectors';

import TextToSpeechVoices from '@constants/TextToSpeechVoices';

function SettingAssetTextToSpeech({ id }) {
  const [clip, setClip] = useRecoilState(clipSelectorFamily(id));

  const handleAssetTextUpdate = () => (event) => {
    const { value } = event.target;
    setClip({ 'asset:meta': { ...clip['asset:meta'], text: value } });
  };

  const handleVoiceChange = useCallback(
    (event) => {
      const { value: effect } = event.target;

      let newValue;
      if (effect.length > 0) {
        newValue = effect;
      }

      setClip({ 'asset:voice': newValue });
    },
    [setClip]
  );

  return (
    <>
      <h6>Text</h6>
      <div className="mb-10">
        <Row>
          <Col md={12}>
            <InputTextarea
              id="text"
              placeholder="Hi {{ NAME }}, welcome to {{ COMPANY }}."
              rows={5}
              value={clip['asset:text']}
              handleChange={handleAssetTextUpdate({ prop: 'text' })}
            />
          </Col>
        </Row>
      </div>

      <h6>Voice</h6>
      <div className="mb-10">
        <Row>
          <Col md={12}>
            <InputSelect
              id="voice"
              title="Voice"
              selected={clip['asset:voice'] || ''}
              values={TextToSpeechVoices}
              prefix={<IconVoiceChat size={18} />}
              handleChange={handleVoiceChange}
            />
          </Col>
        </Row>
      </div>

      <h6>Timing</h6>

      <TimingFields id={id} />
    </>
  );
}

export default SettingAssetTextToSpeech;
