import { useMemo } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useRecoilState, useRecoilValue } from 'recoil';

import InputNumber from '@components/controls/InputNumber';
import OverrideMenu from '@components/mergeFields/OverrideMenu';

import { clipSelectorFamily } from '@store/EditSelectors';
import { clipKeyframesPropertiesEnabledSelectorFamily } from '@store/Keyframes';

function OffsetFields({ id }) {
  const [clip, setClip] = useRecoilState(clipSelectorFamily(id));
  const propertiesKeyframed = useRecoilValue(clipKeyframesPropertiesEnabledSelectorFamily(id));

  const handleOffsetChange = (event) => {
    const { value, id: offsetId } = event.target;
    const axis = offsetId === 'horizontal-offset' ? 'x' : 'y';
    const key = `offset:${axis}`;

    if (value.length > 0 && value >= -1 && value <= 1) {
      setClip({ [key]: parseFloat(value) });
    } else {
      setClip({ [key]: undefined });
    }
  };

  const isOffsetXAnimated = useMemo(() => propertiesKeyframed.includes('offset:x'), [propertiesKeyframed]);
  const isOffsetYAnimated = useMemo(() => propertiesKeyframed.includes('offset:y'), [propertiesKeyframed]);

  return (
    <Row>
      <Col className="d-flex align-items-center justify-content-between gap-2">
        <InputNumber
          id="horizontal-offset"
          title={`Horizontal Offset${isOffsetXAnimated && ' (Animated)'}`}
          value={clip['offset:x'] >= -1 ? clip['offset:x'] : ''}
          disabled={isOffsetXAnimated}
          placeholder="0"
          min={-1}
          max={1}
          step={0.001}
          prefix="X"
          handleChange={handleOffsetChange}
        />
        <OverrideMenu path="offset:x" isAnimated={isOffsetXAnimated} />
      </Col>
      <Col className="d-flex align-items-center justify-content-between gap-2">
        <InputNumber
          id="vertical-offset"
          title={`Vertical Offset${isOffsetYAnimated && ' (Animated)'}`}
          value={clip['offset:y'] >= -1 ? clip['offset:y'] : ''}
          disabled={isOffsetYAnimated}
          placeholder="0"
          min={-1}
          max={1}
          step={0.001}
          prefix="Y"
          handleChange={handleOffsetChange}
        />
        <OverrideMenu path="offset:y" isAnimated={isOffsetYAnimated} />
      </Col>
    </Row>
  );
}

export default OffsetFields;
