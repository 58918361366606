import { useRecoilValue } from 'recoil';

import TimingFields from '@feature/studio/fields/TimingFields';

import AssetSource from '@components/controls/AssetSource';

import { clipSelectorFamily } from '@store/EditSelectors';

function SettingAssetMask({ id }) {
  const clip = useRecoilValue(clipSelectorFamily(id));

  return (
    <>
      <div className="mb-10">
        <AssetSource clip={clip} type="mask" />
      </div>
      <div className="mb-10">
        <h6>Timing</h6>
        <TimingFields id={id} />
      </div>
    </>
  );
}

export default SettingAssetMask;
