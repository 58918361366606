import { Sprite } from '@inlet/react-pixi';
import { useEffect, useMemo, useRef, useState } from 'react';

import drawHandle from '@assets/img/draw-rotate-handler.svg';

const rotatePoint = (cx, cy, x, y, angle) => {
  const radians = (Math.PI / 180) * angle;
  const cos = Math.cos(radians);
  const sin = Math.sin(radians);

  const dx = x - cx;
  const dy = y - cy;

  const xNew = dx * cos - dy * sin;
  const yNew = dx * sin + dy * cos;

  return { x: xNew + cx, y: yNew + cy };
};

function FrameRotationHandle(props) {
  const {
    width,
    coordinates,
    handleGrab,
    handleDrag,
    handleDrop,
    inView,
    isAdjusting,
    position,
    setIsAdjusting,
    setVisible,
    visible,
    zIndex,
    rotationAngle,
    centerX,
    centerY,
  } = props;

  const [isSelected, setIsSelected] = useState(false);
  const [rotatedCoordinates, setRotatedCoordinates] = useState(coordinates);
  const spriteRef = useRef();

  const handlePointerOver = () => setVisible(true);

  const handlePointerOut = () => {
    if (!isAdjusting) {
      setVisible(false);
    }
  };

  const handleOnDrag = (event) => {
    if (isAdjusting && isSelected) {
      handleDrag(event, position);
    }
  };

  const handleOnDrop = (event) => {
    handleDrop(event);
    setIsAdjusting(false);
    setIsSelected(false);
  };

  const handleOnGrab = (event) => {
    handleGrab(event);
    setIsAdjusting(true);
    setIsSelected(true);
  };

  useEffect(() => {
    const { x, y } = coordinates;
    setRotatedCoordinates(rotatePoint(centerX, centerY, x, y, rotationAngle));
  }, [coordinates, rotationAngle, centerX, centerY]);

  const { x, y } = rotatedCoordinates;
  const scale = useMemo(() => {
    if (isNaN(width)) return 0.3;
    return Math.min(1, Math.max(0.1, 0.3 + (width / 200) * 0.5));
  }, [width]);

  return (
    <Sprite
      alpha={visible ? 1 : 0.00001}
      ref={spriteRef}
      image={drawHandle}
      scale={scale}
      anchor={0.5}
      x={x}
      y={y}
      rotation={(rotationAngle * Math.PI) / 180}
      interactive
      cursor="alias"
      pointerdown={handleOnGrab}
      pointermove={handleOnDrag}
      pointerover={handlePointerOver}
      pointerout={handlePointerOut}
      pointerup={handleOnDrop}
      pointerupoutside={handleOnDrop}
      visible={inView}
      zIndex={zIndex}
    />
  );
}

export default FrameRotationHandle;
