import { Container, Graphics, Text } from '@inlet/react-pixi';
import { isNumber } from 'lodash-es';
import { TextStyle } from 'pixi.js';
import { forwardRef, useCallback, useEffect, useMemo, useRef, useState } from 'react';

import withCanvasMovable from '@feature/studio/canvas/CanvasMovable';

import ArtboardMask from '@components/masks/ArtboardMask';

import useFontCheck from '@hooks/useFontCheck';

import { COLOR, FONT_FAMILY, FONT_LINE_HEIGHT, TEXT_ALIGN } from '@constants/TextAssetDefaults';

import formatWebGlColor from '@utils/formatWebGlColor';

const MIN_FONT_SIZE = 1;

const MIN_LINE_HEIGHT = 0.1;

const CanvasTextPlayer = forwardRef((props, ref) => {
  const { clip, visible, x, y, width, height, alpha, scale, zIndex, angle } = props;
  const isFontLoaded = useFontCheck(clip['asset:font:family']);
  const maskRef = useRef(null);

  const [textAnchorX, setTextAnchorX] = useState(0.5);
  const [textAnchorY, setTextAnchorY] = useState(0.5);
  const [textOffsetX, setTextOffsetX] = useState(0);
  const [textOffsetY, setTextOffsetY] = useState(0);
  const [, forceUpdate] = useState({});

  const {
    ['asset:text']: text,
    ['asset:alignment:horizontal']: horizontalAlign = TEXT_ALIGN,
    ['asset:alignment:vertical']: verticalAlign = TEXT_ALIGN,
    ['asset:font:color']: color = COLOR,
    ['asset:font:family']: fontFamily = FONT_FAMILY,
    ['asset:font:size']: fontSize = MIN_FONT_SIZE,
    ['asset:font:lineHeight']: lineHeight = FONT_LINE_HEIGHT,
    ['asset:font:opacity']: fontOpacity = 1,
    ['asset:background:color']: backgroundColor,
    ['asset:background:opacity']: backgroundOpacity = 1,
    ['asset:background:padding']: backgroundPadding = 0,
    ['asset:background:borderRadius']: backgroundBorderRadius = 0,
    ['asset:stroke:color']: strokeColor,
    ['asset:stroke:width']: strokeWidth = 0,
  } = clip;

  useEffect(() => {
    setTimeout(() => {
      forceUpdate({});
    }, 100);
  }, [fontFamily]);

  const bgColor = useMemo(
    () => (backgroundColor && backgroundColor !== 'transparent' ? formatWebGlColor(backgroundColor) : null),
    [backgroundColor]
  );

  const bgOpacity = useMemo(
    () => (backgroundColor && backgroundColor !== 'transparent' ? backgroundOpacity : 0.0001),
    [backgroundColor, backgroundOpacity]
  );

  // multiplying to closely match the stroke width of the text in the edit api
  const strokeWidthForDisplay = useMemo(
    () => (strokeWidth && isNumber(strokeWidth) ? Math.floor(strokeWidth * 2.5) : 0),
    [strokeWidth]
  );

  const boundingBox = useCallback(
    (graphics) => {
      graphics.clear();
      graphics.beginFill(bgColor, bgOpacity);

      const x = -width / 2;
      const y = -height / 2;
      const r = backgroundBorderRadius;

      if (r > 0) {
        graphics.moveTo(x + r, y);
        graphics.arcTo(x + width, y, x + width, y + height, r);
        graphics.arcTo(x + width, y + height, x, y + height, r);
        graphics.arcTo(x, y + height, x, y, r);
        graphics.arcTo(x, y, x + width, y, r);
      } else {
        graphics.drawRect(x, y, width, height);
      }

      graphics.endFill();
    },
    [bgColor, bgOpacity, width, height, backgroundBorderRadius]
  );

  const boundingBoxMaskRef = useRef();

  useEffect(() => {
    const alignmentConfig = {
      horizontal: {
        left: { anchorX: 0, offsetX: -width / 2 + backgroundPadding },
        right: { anchorX: 1, offsetX: width / 2 - backgroundPadding },
        center: { anchorX: 0.5, offsetX: 0 },
      },
      vertical: {
        top: { anchorY: 0, offsetY: -height / 2 + backgroundPadding },
        bottom: { anchorY: 1, offsetY: height / 2 - backgroundPadding },
        center: { anchorY: 0.5, offsetY: 0 },
      },
    };

    const { anchorX, offsetX } = alignmentConfig.horizontal[horizontalAlign] || alignmentConfig.horizontal.center;
    const { anchorY, offsetY } = alignmentConfig.vertical[verticalAlign] || alignmentConfig.vertical.center;

    setTextAnchorX(anchorX);
    setTextOffsetX(offsetX);
    setTextAnchorY(anchorY);
    setTextOffsetY(offsetY);
  }, [horizontalAlign, verticalAlign, width, height, backgroundPadding]);

  useEffect(() => {
    if (ref.current && maskRef.current) {
      ref.current.mask = maskRef.current;
    }
  }, [ref]);

  const textStyle = new TextStyle({
    align: horizontalAlign || TEXT_ALIGN,
    fontFamily: fontFamily || FONT_FAMILY,
    fontSize: `${fontSize}px`,
    fill: color || COLOR,
    lineHeight: lineHeight === 0 ? MIN_LINE_HEIGHT : lineHeight ? fontSize * lineHeight : fontSize * FONT_LINE_HEIGHT,
    wordWrap: true,
    wordWrapWidth: width - 2 * backgroundPadding || 1,
    textBaseline: 'alphabetic',
    stroke: strokeColor || 'transparent',
    strokeThickness: strokeWidthForDisplay || 0,
  });

  return (
    <>
      <Container
        ref={ref}
        anchor={0.5}
        visible={visible}
        x={x}
        y={y}
        width={width}
        height={height}
        alpha={alpha}
        scale={isNumber(scale) ? scale : 1}
        zIndex={zIndex}
        angle={angle}
      >
        <Graphics draw={boundingBox} />
        <Graphics name="mask" draw={boundingBox} ref={boundingBoxMaskRef} />
        {Boolean(text) && isFontLoaded && (
          <Text
            text={text}
            anchor={[textAnchorX, textAnchorY]}
            x={textOffsetX}
            y={textOffsetY}
            style={textStyle}
            alpha={fontOpacity}
          />
        )}
      </Container>
      <ArtboardMask ref={maskRef} />
    </>
  );
});

export default withCanvasMovable(CanvasTextPlayer);
