import { useRecoilValue } from 'recoil';

import { IconMask } from '@assets/icons';

import Asset from '@components/asset/Asset';

import { clipSelectorFamily } from '@store/EditSelectors';

import useCaptureFrames from '@hooks/useCaptureFrames';

import getFileTypeFromUrl from '@utils/getFileTypeFromUrl';

const getPoster = (src, frames) => {
  try {
    const filetype = getFileTypeFromUrl(src);
    return filetype === 'image' ? src : frames?.data?.data[2];
  } catch (error) {
    return null;
  }
};

function AssetMask({ id }) {
  const { ['asset:src']: src } = useRecoilValue(clipSelectorFamily(id));
  const { frames } = useCaptureFrames({ src, options: { fps: 3 } });
  const poster = getPoster(src, frames);
  return <Asset icon={<IconMask />}>{poster ? <img src={poster} alt="mask" /> : <div />}</Asset>;
}

export default AssetMask;
