import * as PIXI from 'pixi.js';
import { createContext, useCallback, useContext, useEffect, useMemo, useRef } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';

import { timelineClipTimesSelector, timelineDurationSelector } from '@store/EditSelectors';
import { fpsAtom } from '@store/Output';
import { playableSelector, playheadAtom, playheadIsPausedAtom, playheadIsStoppedAtom } from '@store/Timeline';

import useSoundtrack from '@hooks/useSoundtrack';

export const TimelinePlaybackContext = createContext();
export const useTimelinePlaybackContext = () => useContext(TimelinePlaybackContext);

export const TimelinePlayheadContext = createContext();
export const useTimelinePlayheadContext = () => useContext(TimelinePlayheadContext);

export function TimelinePlaybackProvider({ children }) {
  const [isPaused, setIsPaused] = useRecoilState(playheadIsPausedAtom);
  const [isStopped, setIsStopped] = useRecoilState(playheadIsStoppedAtom);
  const [playhead, setPlayhead] = useRecoilState(playheadAtom);
  const isPlayable = useRecoilValue(playableSelector);
  const timelineDuration = useRecoilValue(timelineDurationSelector);
  const timelinePoints = useRecoilValue(timelineClipTimesSelector);
  const outputFps = useRecoilValue(fpsAtom);

  const soundtrack = useSoundtrack();

  const initialPlayheadTimeRef = useRef();
  const fpsThrottleRef = useRef();
  const tickerRef = useRef(new PIXI.Ticker());
  const playheadRef = useRef(0);

  const fps = 60;
  const fpsInterval = 1000 / fps;

  const formatTimecode = (seconds) => {
    const clampedSeconds = Math.max(0, seconds);
    const totalFrames = Math.round(clampedSeconds * outputFps);
    const totalSeconds = Math.floor(totalFrames / outputFps);
    const hours = Math.floor(totalSeconds / 3600);
    const minutes = Math.floor((totalSeconds % 3600) / 60);
    const secs = totalSeconds % 60;
    const frames = Math.round(totalFrames % outputFps);

    return `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}:${String(secs).padStart(2, '0')}.${String(frames).padStart(2, '0')}`;
  };

  const animate = useCallback(() => {
    const now = Date.now();
    const playheadPosition = now - initialPlayheadTimeRef.current;
    const elapsed = now - fpsThrottleRef.current;

    if (elapsed > fpsInterval) {
      const newPlayhead = playheadPosition / 1000;
      playheadRef.current = newPlayhead;
      setPlayhead(newPlayhead);
      fpsThrottleRef.current = now - (elapsed % fpsInterval);
    }
  }, [fpsInterval, setPlayhead]);

  const handlePlay = useCallback(() => {
    if (timelineDuration <= 0) return;

    const currentTime = Date.now();
    initialPlayheadTimeRef.current = currentTime - playheadRef.current * 1000;

    setIsStopped(false);
    setIsPaused(false);
    soundtrack?.play({ start: playheadRef.current, end: timelineDuration });
    fpsThrottleRef.current = currentTime;

    tickerRef.current.add(animate);
    tickerRef.current.start();
  }, [animate, timelineDuration, setIsPaused, setIsStopped, soundtrack]);

  const handlePause = useCallback(() => {
    setIsPaused(true);
    setIsStopped(false);
    soundtrack?.pause();

    tickerRef.current.remove(animate);
    tickerRef.current.stop();
  }, [animate, setIsPaused, setIsStopped, soundtrack]);

  const handlePlayPause = useCallback(() => {
    if (isPaused || isStopped) {
      handlePlay();
    } else {
      handlePause();
    }
  }, [isPaused, isStopped, handlePlay, handlePause]);

  const handleStop = useCallback(() => {
    setIsPaused(false);
    setIsStopped(true);
    tickerRef.current.remove(animate);
    tickerRef.current.stop();
    playheadRef.current = 0;
    setPlayhead(0);
    soundtrack?.stop();
  }, [animate, setIsPaused, setIsStopped, setPlayhead, soundtrack]);

  const setPlayheadStart = useCallback(() => {
    handlePause();
    playheadRef.current = 0;
    setPlayhead(0);
  }, [handlePause, setPlayhead]);

  const setPlayheadEnd = useCallback(() => {
    handlePause();
    playheadRef.current = timelineDuration;
    setPlayhead(timelineDuration);
  }, [handlePause, setPlayhead, timelineDuration]);

  const seek = useCallback(
    ({ dir, snap }) => {
      handlePause();

      const getSnapValue = (state) => {
        if (dir === 'back' && state < 0) return 0;
        if (dir === 'forward' && state > timelineDuration) return timelineDuration;

        const nearestPoints = timelinePoints.filter((point) => (dir === 'back' ? point < state : point > state));
        const nearestPoint = dir === 'back' ? nearestPoints.pop() : nearestPoints[0];

        const snapMaps = {
          points: nearestPoint || (dir === 'back' ? 0 : timelineDuration),
          fps: dir === 'back' ? state - 1 / outputFps : state + 1 / outputFps,
          percentage: dir === 'back' ? state - timelineDuration * 0.1 : state + timelineDuration * 0.1,
        };

        return snapMaps[snap] ?? state;
      };

      const newPlayhead = getSnapValue(playheadRef.current);
      playheadRef.current = newPlayhead;
      setPlayhead(newPlayhead);
    },
    [handlePause, timelineDuration, timelinePoints, outputFps, setPlayhead]
  );

  useEffect(() => {
    if (timelineDuration > 0 && playheadRef.current >= timelineDuration) {
      setIsPaused(false);
      setIsStopped(true);
      tickerRef.current.remove(animate);
      tickerRef.current.stop();
      playheadRef.current = timelineDuration;
      setPlayhead(timelineDuration);
      soundtrack?.stop();
    }
  }, [timelineDuration, animate, setIsPaused, setIsStopped, setPlayhead, soundtrack]);

  useEffect(() => {
    if (isPaused || isStopped) {
      const newPlayhead = Math.min(playhead, timelineDuration);
      playheadRef.current = newPlayhead;
      setPlayhead(newPlayhead);
    }
  }, [playhead, isPaused, isStopped, timelineDuration, setPlayhead]);

  useEffect(() => {
    return () => {
      tickerRef.current.remove(animate);
      tickerRef.current.stop();
      handleStop();
    };
  }, []);

  const playbackContextValue = useMemo(
    () => ({
      isPaused,
      isStopped,
      isPlayable,
      isPlaying: !isPaused && !isStopped,
      timelineDuration,
      duration: formatTimecode(timelineDuration),
      handlePlay,
      handlePause,
      handleStop,
      handlePlayPause,
      setPlayhead,
      setPlayheadStart,
      setPlayheadEnd,
      seek,
      formatTimecode,
    }),
    [
      isPaused,
      isStopped,
      isPlayable,
      timelineDuration,
      handlePlay,
      handlePause,
      handleStop,
      handlePlayPause,
      setPlayhead,
      setPlayheadStart,
      setPlayheadEnd,
      seek,
    ]
  );

  const playheadContextValue = useMemo(
    () => ({
      playhead: playheadRef.current,
      timecode: formatTimecode(playheadRef.current),
    }),
    [formatTimecode]
  );

  return (
    <TimelinePlaybackContext.Provider value={playbackContextValue}>
      <TimelinePlayheadContext.Provider value={playheadContextValue}>{children}</TimelinePlayheadContext.Provider>
    </TimelinePlaybackContext.Provider>
  );
}
