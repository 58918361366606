import config from '@config';

// ENABLE_MY_FEATURE: false,
export const FEATURE_FLAGS = {
  ENABLE_AUTOMATE_TABS: config.isDev,
};

// ENABLE_MY_FEATURE: 'MY FEATURE',
export const FEATURE_FLAGS_NAMES = {
  ENABLE_AUTOMATE_TABS: 'Automate Tabs',
};
