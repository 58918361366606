import { useRecoilValue } from 'recoil';

import CanvasAudio from '@feature/studio/canvas/CanvasAudio';
import CanvasCaption from '@feature/studio/canvas/CanvasCaption';
import CanvasHtml from '@feature/studio/canvas/CanvasHtml';
import CanvasImage from '@feature/studio/canvas/CanvasImage';
import CanvasImageToVideo from '@feature/studio/canvas/CanvasImageToVideo';
import CanvasText from '@feature/studio/canvas/CanvasText';
import CanvasTextToImage from '@feature/studio/canvas/CanvasTextToImage';
import CanvasTextToSpeech from '@feature/studio/canvas/CanvasTextToSpeech';
import CanvasVideo from '@feature/studio/canvas/CanvasVideo';

import { clipSelectorFamily } from '@store/EditSelectors';

const CanvasComponentMap = {
  audio: CanvasAudio,
  html: CanvasHtml,
  text: CanvasText,
  image: CanvasImage,
  video: CanvasVideo,
  overlay: CanvasVideo,
  caption: CanvasCaption,
  'text-to-speech': CanvasTextToSpeech,
  'text-to-image': CanvasTextToImage,
  'image-to-video': CanvasImageToVideo,
};

function CanvasClip({ id, index }) {
  const clip = useRecoilValue(clipSelectorFamily(id));
  const CanvasComponent = CanvasComponentMap[clip['asset:type']];

  if (!CanvasComponent) {
    return null;
  }

  return <CanvasComponent key={id} id={id} index={index} />;
}

export default CanvasClip;
