// https://react-icons.github.io/react-icons/
import { AiOutlineCloud, AiOutlineCloudSync, AiOutlineCode } from 'react-icons/ai';
import {
  BiBorderRadius,
  BiLogoFacebookSquare,
  BiLogoInstagramAlt,
  BiLogoLinkedin,
  BiLogoPinterest,
  BiLogoTiktok,
  BiLogoTwitter,
  BiLogoYoutube,
  BiMinusCircle,
  BiPlusCircle,
  BiSolidArrowFromLeft,
  BiSolidErrorAlt,
} from 'react-icons/bi';
import {
  BsArrowCounterclockwise,
  BsArrowLeftRight,
  BsArrowReturnRight,
  BsArrowRight,
  BsBoxArrowUpRight,
  BsBoxes,
  BsBraces,
  BsBracesAsterisk,
  BsCameraVideo,
  BsCheck,
  BsCheckCircleFill,
  BsCheckLg,
  BsChevronDown,
  BsChevronLeft,
  BsChevronRight,
  BsChevronUp,
  BsClipboard,
  BsClipboardCheck,
  BsCode,
  BsCodeSlash,
  BsDoorOpenFill,
  BsEnvelopeHeart,
  BsExclamationCircleFill,
  BsExclamationTriangle,
  BsExclamationTriangleFill,
  BsFileBinary,
  BsFiles,
  BsFilm,
  BsFonts,
  BsFullscreen,
  BsGearFill,
  BsGripHorizontal,
  BsGripVertical,
  BsHr,
  BsInfoSquare,
  BsLayersHalf,
  BsLightningCharge,
  BsLightningChargeFill,
  BsLink45Deg,
  BsMask,
  BsMusicNoteBeamed,
  BsNutFill,
  BsPauseFill,
  BsPencilSquare,
  BsPlayFill,
  BsServer,
  BsSlashCircle,
  BsSoundwave,
  BsStopFill,
  BsThreeDots,
  BsWindow,
  BsX,
  BsZoomIn,
  BsZoomOut,
} from 'react-icons/bs';
import { CgArrowsShrinkH, CgCaptions, CgEditNoise } from 'react-icons/cg';
import { FaGripVertical } from 'react-icons/fa';
import { FaSquareXTwitter } from 'react-icons/fa6';
import { GiResize } from 'react-icons/gi';
import { GoMoveToEnd, GoMoveToStart } from 'react-icons/go';
import { GrMultimedia, GrRedo, GrUndo } from 'react-icons/gr';
import { HiMiniBolt } from 'react-icons/hi2';
import { ImFontSize } from 'react-icons/im';
import { IoIosWarning } from 'react-icons/io';
import { LiaKeySolid } from 'react-icons/lia';
import {
  LuAlignCenter,
  LuAlignLeft,
  LuAlignRight,
  LuClock3,
  LuCoins,
  LuEye,
  LuEyeOff,
  LuFileSpreadsheet,
  LuKeyboard,
  LuLayoutTemplate,
  LuListPlus,
  LuSquareSlash,
  LuWorkflow,
} from 'react-icons/lu';
import {
  MdAlignVerticalBottom,
  MdAlignVerticalCenter,
  MdAlignVerticalTop,
  MdAlternateEmail,
  MdCached,
  MdFormatColorFill,
  MdFormatColorReset,
  MdFormatColorText,
  MdOutlineAnimation,
  MdOutlineAutofpsSelect,
  MdOutlineCompress,
  MdOutlineCrop,
  MdOutlineDesignServices,
  MdOutlineDriveFolderUpload, // MdOutlineDesignServices,
  MdOutlinePermMedia,
  MdOutlineWebhook,
  MdVoiceChat,
} from 'react-icons/md';
import {
  PiDotOutlineFill,
  PiDotThin,
  PiFilmStripLight,
  PiImage,
  PiMagicWand,
  PiPlugs,
  PiShapesFill,
  PiToolbox,
} from 'react-icons/pi';
import {
  RiArrowLeftSFill,
  RiArrowRightSFill,
  RiAspectRatioLine,
  RiLineHeight,
  RiPlayList2Fill,
  RiQuestionLine,
  RiScreenshot2Fill,
  RiSpeedUpFill,
  RiVolumeMuteFill,
  RiVolumeUpFill,
} from 'react-icons/ri';
import { RxBorderWidth, RxDimensions, RxFontFamily, RxHamburgerMenu, RxTransparencyGrid } from 'react-icons/rx';
import { SiMake, SiMaterialdesignicons, SiZapier } from 'react-icons/si';
import {
  TbApi,
  TbBoxPadding,
  TbEaseInOut,
  TbKeyframe,
  TbKeyframeFilled,
  TbKeyframes,
  TbLock,
  TbLockOff,
  TbRotateDot,
  TbSquareAsterisk,
  TbSquareAsteriskFilled,
  TbTrashX,
} from 'react-icons/tb';
import { TiDeleteOutline } from 'react-icons/ti';

export const IconAlignBottom = MdAlignVerticalBottom;
export const IconAlignTop = MdAlignVerticalTop;
export const IconAlignCenterVertically = MdAlignVerticalCenter;
export const IconTextAlignCenter = LuAlignCenter;
export const IconTextAlignLeft = LuAlignLeft;
export const IconTextAlignRight = LuAlignRight;
export const IconMusicNoteBeamed = BsMusicNoteBeamed;
export const IconFonts = RxFontFamily;
export const IconCode = BsCode;
export const IconImage = PiImage;
export const IconMask = BsMask;
export const IconOverlay = BsLayersHalf;
export const IconCameraVideo = BsCameraVideo;
export const IconChevronLeft = BsChevronLeft;
export const IconLink = BsLink45Deg;
export const IconTrash = TbTrashX;
export const IconKeyboard = LuKeyboard;
export const IconQuestionCircle = RiQuestionLine;
export const IconBraces = BsBraces;
export const IconCollectionPlay = RiPlayList2Fill;
export const IconGear = BsGearFill;
export const IconCodeSlash = BsCodeSlash;
export const IconCheckCircle = BsCheckCircleFill;
export const IconExclamationCircle = BsExclamationCircleFill;
export const IconSlashCircle = BsSlashCircle;
export const IconMerge = BsBraces;
export const IconOverride = BsBraces;
export const IconOverriden = BsBracesAsterisk;
export const IconOverrideItem = TbSquareAsterisk;
export const IconOverrideItemSelected = TbSquareAsteriskFilled;
export const IconKeyframes = TbKeyframes;
export const IconKeyframe = TbKeyframe;
export const IconKeyframeSelected = TbKeyframeFilled;
export const IconExternal = BsBoxArrowUpRight;
export const IconClipboard = BsClipboard;
export const IconClipboardCheck = BsClipboardCheck;
export const IconReset = BsArrowCounterclockwise;
export const IconServer = BsServer;
export const IconCSV = LuFileSpreadsheet;
export const IconAudio = BsMusicNoteBeamed;
export const IconChevronDown = BsChevronDown;
export const IconChevronUp = BsChevronUp;
export const IconVideo = BsFilm;
export const IconHr = BsHr;
export const IconExclamationTriangle = BsExclamationTriangle;
export const IconExclamationTriangleFill = BsExclamationTriangleFill;
export const IconPlusCircle = BiPlusCircle;
export const IconMinusCircle = BiMinusCircle;
export const IconFiles = BsFiles;
export const IconPencilSquare = BsPencilSquare;
export const IconDuration = LuClock3;
export const IconStartEnd = BsArrowLeftRight;
export const IconMore = BsThreeDots;
export const IconPause = BsPauseFill;
export const IconPlay = BsPlayFill;
export const IconStop = BsStopFill;
export const IconZoomIn = BsZoomIn;
export const IconZoomOut = BsZoomOut;
export const IconDrag = FaGripVertical;
export const IconText = BsFonts;
export const IconVisible = LuEye;
export const IconInvisible = LuEyeOff;
export const IconProperties = BsBoxes;
export const IconCheck = BsCheck;
export const IconCheckLg = BsCheckLg;
export const IconArrowRight = BsArrowRight;
export const IconClose = BsX;
export const IconDelete = TiDeleteOutline;
export const IconChevronRight = BsChevronRight;
export const IconInfo = BsInfoSquare;
export const IconArrowReturnRight = BsArrowReturnRight;
export const IconNutBolt = BsNutFill;
export const IconDoorOpen = BsDoorOpenFill;
export const IconLightningCharge = BsLightningChargeFill;
export const IconWorkflow = LuWorkflow;
export const IconWarning = IoIosWarning;
export const IconMedia = MdOutlinePermMedia;
export const IconError = BiSolidErrorAlt;
export const IconCoins = LuCoins;
export const IconBolt = HiMiniBolt;
export const IconDragHorizontal = BsGripHorizontal;
export const IconDragVertical = BsGripVertical;
export const IconMenu = RxHamburgerMenu;
export const IconFullscreen = BsFullscreen;
export const IconWindow = BsWindow;
export const IconEnvelopeHeart = BsEnvelopeHeart;
export const IconAddMedia = GrMultimedia;
export const IconIntegrations = PiPlugs;
export const IconElements = PiShapesFill;
export const IconAddTrack = LuListPlus;
export const IconCloud = AiOutlineCloud;
export const IconCloudSync = AiOutlineCloudSync;
export const IconStart = GoMoveToStart;
export const IconEnd = GoMoveToEnd;
export const IconTransparent = RxTransparencyGrid;
export const IconFontSize = ImFontSize;
export const IconLineHeight = RiLineHeight;
export const IconFormatColorText = MdFormatColorText;
export const IconFormatColorReset = MdFormatColorReset;
export const IconFormatColorFill = MdFormatColorFill;
export const IconFit = MdOutlineCrop;
export const IconScale = GiResize;
export const IconTrimStart = BiSolidArrowFromLeft;
export const IconVolumeFull = RiVolumeUpFill;
export const IconVolumeMute = RiVolumeMuteFill;
export const IconSoundEffect = BsSoundwave;
export const IconScreenshot = RiScreenshot2Fill;
export const IconDimensions = RxDimensions;
export const IconFile = BsFileBinary;
export const IconFps = MdOutlineAutofpsSelect;
export const IconCompress = MdOutlineCompress;
export const IconWebhook = MdOutlineWebhook;
export const IconCached = MdCached;
export const IconUndo = GrUndo;
export const IconRedo = GrRedo;
export const IconRotate = TbRotateDot;
export const IconShortcut = LuSquareSlash;
export const IconThreshold = CgArrowsShrinkH;
export const IconHalo = CgEditNoise;
export const IconCaptions = CgCaptions;
export const IconBorderRadius = BiBorderRadius;
export const IconPadding = TbBoxPadding;
export const IconSpeed = RiSpeedUpFill;
export const IconStroke = MdFormatColorFill;
export const IconStrokeWidth = RxBorderWidth;
export const IconEase = TbEaseInOut;
export const IconKeyframePrevious = RiArrowLeftSFill;
export const IconKeyframeNext = RiArrowRightSFill;
export const IconAnimations = MdOutlineAnimation;
export const IconNotificationDot = PiDotOutlineFill;
export const IconDesign = SiMaterialdesignicons;
export const IconDesigns = MdOutlineDesignServices;
export const IconConnect = IconIntegrations;
export const IconAutomate = BsLightningCharge;
export const IconDot = PiDotThin;
export const IconTemplate = LuLayoutTemplate;
export const IconRenders = PiFilmStripLight;
export const IconUploads = MdOutlineDriveFolderUpload;
export const IconApiPlayground = AiOutlineCode;
export const IconUtilities = PiToolbox;
export const IconKey = LiaKeySolid;
export const IconMagicWand = PiMagicWand;
export const IconVoiceChat = MdVoiceChat;
export const IconAlias = MdAlternateEmail;
export const IconLocked = TbLockOff;
export const IconUnlocked = TbLock;
export const IconBrandFacebook = BiLogoFacebookSquare;
export const IconBrandInstagram = BiLogoInstagramAlt;
export const IconBrandTikTok = BiLogoTiktok;
export const IconBrandLinkedIn = BiLogoLinkedin;
export const IconBrandYouTube = BiLogoYoutube;
export const IconBrandTwitter = BiLogoTwitter;
export const IconBrandX = FaSquareXTwitter;
export const IconBrandPinterest = BiLogoPinterest;
export const IconAspectRatio = RiAspectRatioLine;
export const IconApi = TbApi;
export const IconZapier = SiZapier;
export const IconMake = SiMake;
