import { useMemo } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useRecoilState, useRecoilValue } from 'recoil';

import { IconRotate } from '@assets/icons';

import InputNumber from '@components/controls/InputNumber';
import OverrideMenu from '@components/mergeFields/OverrideMenu';

import { clipSelectorFamily } from '@store/EditSelectors';
import { clipKeyframesPropertiesEnabledSelectorFamily } from '@store/Keyframes';

const property = 'transform:rotate:angle';

function TransformFields({ id }) {
  const [clip, setClip] = useRecoilState(clipSelectorFamily(id));
  const propertiesKeyframed = useRecoilValue(clipKeyframesPropertiesEnabledSelectorFamily(id));

  const handleRotateChange = (event) => {
    const { value } = event.target;

    if (value.length > 0 && value >= -360 && value <= 360) {
      setClip({ [property]: parseFloat(value) });
    } else {
      setClip({ [property]: undefined });
    }
  };

  const isRotateAnimated = useMemo(() => propertiesKeyframed.includes(property), [propertiesKeyframed]);

  return (
    <Row>
      <Col md={6} className="d-flex align-items-center justify-content-between gap-2">
        <InputNumber
          id="rotate"
          title={`Rotate${isRotateAnimated && ' (Animated)'}`}
          value={clip[property] >= -360 ? clip[property] : ''}
          disabled={isRotateAnimated}
          placeholder="0"
          min={-360}
          max={360}
          step={0.1}
          prefix={<IconRotate size={16} />}
          handleChange={handleRotateChange}
        />
        <OverrideMenu path="transform:rotate:angle" />
      </Col>
    </Row>
  );
}

export default TransformFields;
