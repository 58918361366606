import { useRecoilValue } from 'recoil';
import { v4 as uuid } from 'uuid';

import '@assets/css/PanelMediaListing.css';

import MediaListingPreview from '@components/asset/MediaListingPreview';

import { templateMediaSelectorFamily, useAddClipToTimelineCallback } from '@store/Media';

const titleMap = {
  media: 'Media',
  overlay: 'Overlays',
  mask: 'Mask',
  caption: 'Captions',
  element: 'Elements',
  default: 'Items',
};

function PanelTemplateMediaListing({ type }) {
  const mediaList = useRecoilValue(templateMediaSelectorFamily(type));
  const addClipToTimeline = useAddClipToTimelineCallback();

  return (
    <div className="media-listing">
      <div>
        <h6>In Template</h6>
      </div>

      <div className="media-listing__container">
        {(mediaList || []).map((media) => (
          <MediaListingPreview
            key={uuid()}
            media={media}
            onClick={() => addClipToTimeline({ data: media, type: media.type })}
          />
        ))}
      </div>

      {!mediaList.length && (
        <div className="helper-text centered">
          <span>{titleMap[type] || titleMap.default} in your template will appear here</span>
        </div>
      )}
    </div>
  );
}

export default PanelTemplateMediaListing;
