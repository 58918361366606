import { Scrollbar } from 'react-scrollbars-custom';
import { useRecoilValue } from 'recoil';

import PanelMediaListing from '@feature/studio/panel/PanelMediaListing';
import PanelMediaUpload from '@feature/studio/panel/PanelMediaUpload';
import PanelTemplateMediaListing from '@feature/studio/panel/PanelTemplateMediaListing';

import { sdkOptionsSelector } from '@store/Sdk';

function PanelMediaDefault() {
  const { isSdkEnabled } = useRecoilValue(sdkOptionsSelector);

  return (
    <div className="workspace__panel-inner">
      <Scrollbar noScrollX style={{ width: '100%', height: '100%' }}>
        <div className="mb-4">Add Media</div>
        {!isSdkEnabled && <PanelMediaUpload />}
        <PanelTemplateMediaListing type="media" />
        {!isSdkEnabled && <PanelMediaListing type="media" />}
      </Scrollbar>
    </div>
  );
}

export default PanelMediaDefault;
