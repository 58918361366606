import { memo } from 'react';
import { useRecoilValue } from 'recoil';

import CanvasStage from '@feature/studio/canvas/CanvasStage';
import TimelineControls from '@feature/studio/timeline/TimelineControls';
import TimelineSdkControls from '@feature/studio/timeline/TimelineSdkControls';

import { sdkOptionsSelector } from '@store/Sdk';

import TemplateConfiguration from '../configuration/TemplateConfiguration';

function WorkspaceCanvas() {
  const { isSdkEnabled, isTimeline } = useRecoilValue(sdkOptionsSelector);

  return (
    <div className={isSdkEnabled && !isTimeline ? 'workspace__canvas--sdk' : 'workspace__canvas'}>
      {!isSdkEnabled && <TemplateConfiguration />}
      <CanvasStage />
      <div className="workspace__controls">
        {isSdkEnabled && !isTimeline ? <TimelineSdkControls /> : <TimelineControls />}
      </div>
    </div>
  );
}

export default memo(WorkspaceCanvas);
