import { useCallback } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useRecoilState } from 'recoil';

import { IconSoundEffect, IconTrimStart, IconVolumeFull } from '@assets/icons';

import TimingFields from '@feature/studio/fields/TimingFields';

import AssetSource from '@components/controls/AssetSource';
import InputNumber from '@components/controls/InputNumber';
import InputSelect from '@components/controls/InputSelect';
import OverrideMenu from '@components/mergeFields/OverrideMenu';

import { clipSelectorFamily } from '@store/EditSelectors';

import VolumeEffects from '@constants/VolumeEffects';

function SettingAssetAudio({ id }) {
  const [clip, setClip] = useRecoilState(clipSelectorFamily(id));

  const handleVolumeEffectChange = useCallback(
    (event) => {
      const { value: effect } = event.target;

      let newValue;
      if (effect.length > 0) {
        newValue = effect;
      }

      setClip({ 'asset:effect': newValue });
    },
    [setClip]
  );

  const handleTrimChange = useCallback(
    (event) => {
      const { value: trim } = event.target;

      let newValue;
      if (trim.length > 0 && trim >= 0) {
        newValue = parseFloat(trim);
      }

      setClip({ 'asset:trim': newValue });
    },
    [setClip]
  );

  const handleVolumeChange = useCallback(
    (event) => {
      const { value: volume } = event.target;

      let newValue;
      if (volume.length > 0 && volume >= 0 && volume <= 1) {
        newValue = parseFloat(volume);
      }
      setClip({ 'asset:volume': newValue });
    },
    [setClip]
  );

  return (
    <>
      <div className="mb-10">
        <AssetSource id={id} type="audio" />
      </div>

      <h6>Trim &amp; Volume</h6>
      <div className="mb-10">
        <Row className="mb-2">
          <Col md={6} className="d-flex align-items-center justify-content-between gap-2">
            <InputNumber
              id="trim-start"
              title="Trim Start"
              placeholder="0"
              value={clip['asset:trim'] >= 0 ? clip['asset:trim'] : ''}
              min={0}
              step={0.01}
              handleChange={handleTrimChange}
              prefix={<IconTrimStart size={18} />}
            />
            <OverrideMenu path="asset:trim" />
          </Col>
          <Col md={6} className="d-flex align-items-center justify-content-between gap-2">
            <InputNumber
              id="volume"
              title="Volume"
              placeholder="0"
              value={clip['asset:volume'] >= 0 ? clip['asset:volume'] : ''}
              max={1}
              min={0}
              step={0.01}
              handleChange={handleVolumeChange}
              prefix={<IconVolumeFull size={18} />}
            />
            <OverrideMenu path="asset:volume" />
          </Col>
        </Row>

        <Row>
          <Col className="d-flex align-items-center justify-content-between gap-2">
            <InputSelect
              id="volume"
              title="Volume Effect"
              selected={clip['asset:effect'] || ''}
              values={VolumeEffects}
              prefix={<IconSoundEffect size={18} />}
              handleChange={handleVolumeEffectChange}
            />
            <OverrideMenu path="asset:effect" />
          </Col>
        </Row>
      </div>

      <h6>Timing</h6>

      <TimingFields id={id} />
    </>
  );
}

export default SettingAssetAudio;
