import { atom, atomFamily, selector, selectorFamily, useRecoilCallback } from 'recoil';
import { v4 as uuid } from 'uuid';

import { activeClipAtom, activeTrackAtom } from '@store/Clips';
import { assetIdsAtom, clipIdsAtom, clipsAtomFamily, clipsTracksAtomFamily, trackIdsAtom } from '@store/Edit';
import { clipSelectorFamily, trackClipIdsSelectorFamily } from '@store/EditSelectors';
import { copyPasteClipKeyframesCallback } from '@store/Keyframes';
import { templateMediaSelectorFamily } from '@store/Media';
import { formatAtom } from '@store/Output';

import { ASSET_TYPES_MASK } from '@constants/AssetTypes';
import { TIMELINE_SCALE_DEFAULT } from '@constants/Timeline';

import roundToPrecision from '@utils/math/roundToPrecision';
import { getSnapshot } from '@utils/recoil';
import { getClipsGroupedByIntersect, multipleIntersectingClips, singleIntersectingClip } from '@utils/timeline';
import { getClipStartTime } from '@utils/tracks';

export const FIRST_FRAME_TIME = 0;
export const PLAYABLE_FORMATS = ['mp4', 'mp3'];

export const timelineScaleAtom = atom({
  key: 'timelineScaleAtom',
  default: TIMELINE_SCALE_DEFAULT,
});

export const playheadAtom = atom({
  key: 'playheadAtom',
  default: FIRST_FRAME_TIME,
});

export const playheadIsPlayingAtom = atom({
  key: 'playheadIsPlayingAtom',
  default: false,
});

export const playheadIsPausedAtom = atom({
  key: 'playheadIsPausedAtom',
  default: false,
});

export const playheadIsStoppedAtom = atom({
  key: 'playheadIsStoppedAtom',
  default: true,
});

export const playableSelector = selector({
  key: 'playable',
  get: ({ get }) => {
    const format = get(formatAtom);
    return PLAYABLE_FORMATS.includes(format);
  },
});

export const playbackMuteAtom = atom({
  key: 'playbackMuteAtom',
  default: false,
});

export const trackMaskVisibleAtomFamily = atomFamily({
  key: 'trackMaskVisibleAtomFamily',
  default: false,
});

export const trackVisibleAtomFamily = atomFamily({
  key: 'trackVisibleAtomFamily',
  default: true,
});

export const trackLockedAtomFamily = atomFamily({
  key: 'trackLockedAtomFamily',
  default: false,
});

export const toggleTrackVisibleSelectorFamily = selectorFamily({
  key: 'toggleTrackVisibleSelectorFamily',
  get:
    (trackId) =>
    ({ get }) => {
      return get(trackVisibleAtomFamily(trackId));
    },
  set:
    (trackId) =>
    ({ set }) => {
      set(trackVisibleAtomFamily(trackId), (prev) => !prev);
    },
});

export const toggleTrackLockedSelectorFamily = selectorFamily({
  key: 'toggleTrackLockedSelectorFamily',
  get:
    (trackId) =>
    ({ get }) => {
      return get(trackLockedAtomFamily(trackId));
    },
  set:
    (trackId) =>
    ({ set }) => {
      set(trackLockedAtomFamily(trackId), (prev) => !prev);
    },
});

export const toggleTrackMaskVisibleSelectorFamily = selectorFamily({
  key: 'toggleTrackMaskVisibleSelectorFamily',
  get:
    (trackId) =>
    ({ get }) => {
      return get(trackMaskVisibleAtomFamily(trackId));
    },
  set:
    (trackId) =>
    ({ set }) => {
      set(trackMaskVisibleAtomFamily(trackId), (prev) => !prev);
    },
});

export const addTrackState = (callbackArgs) => {
  const { set, snapshot } = callbackArgs;
  return (type) => {
    const trackId = uuid();
    const trackIds = snapshot.getLoadable(trackIdsAtom).contents;
    const newTrackIds = [trackId, ...trackIds];

    set(trackIdsAtom, newTrackIds);
    set(trackMaskVisibleAtomFamily(trackId), type === 'mask');

    // update each clip's trackIndex to match new trackIds
    const clipIds = snapshot.getLoadable(clipIdsAtom).contents;
    clipIds.forEach((clipId) => {
      const clipTrackId = snapshot.getLoadable(clipsTracksAtomFamily(clipId)).contents.trackId;
      const clipTrackIndex = newTrackIds.findIndex((id) => id === clipTrackId);
      set(clipsTracksAtomFamily(clipId), (currentState) => ({ ...currentState, trackIndex: clipTrackIndex }));
    });

    return trackId;
  };
};

export const addClipState = (callbackArgs) => {
  const { set, snapshot } = callbackArgs;
  return ({ toTrackId, clip }) => {
    const newClipId = uuid();
    const newAssetId = uuid();

    const startTime = snapshot.getLoadable(playheadAtom).contents;
    const clipProperties = {
      ...clip,
      id: newClipId,
      start: startTime,
      length: clip.length || 3,
      meta: {
        ...clip.meta,
        start: 'number',
        length: clip.type === 'caption' ? 'end' : 'auto',
      },
    };

    set(clipIdsAtom, (currentState) => [...currentState, newClipId]);
    set(assetIdsAtom, (currentState) => [...currentState, newAssetId]);

    const newTrackIndex = snapshot.getLoadable(trackIdsAtom).contents.findIndex((id) => id === toTrackId);
    const toTrackIndex = Math.max(0, newTrackIndex);

    const toTrackClipIds = snapshot.getLoadable(trackClipIdsSelectorFamily(toTrackId)).contents;
    const toTrackClips = toTrackClipIds.map((clipId) => {
      // Todo: try clipState
      const clipSnapshot = getSnapshot({ snapshot, id: clipId, family: clipsAtomFamily });
      return { ...clipSnapshot };
    });
    clipProperties.start = getClipStartTime(toTrackClips, clipProperties);

    set(clipsAtomFamily(newClipId), clipProperties);
    set(clipsTracksAtomFamily(newClipId), { trackId: toTrackId, trackIndex: toTrackIndex });

    return clipProperties;
  };
};

export const addElementClipState = (callbackArgs) => {
  const { set, snapshot } = callbackArgs;
  return ({ toTrackId, clips }) => {
    let startTime = snapshot.getLoadable(playheadAtom).contents;

    const newClipIds = clips.map((clip) => {
      const { asset, meta, ...restClip } = clip;
      const newClipId = uuid();
      const newAssetId = uuid();

      const clipProperties = {
        ...restClip,
        id: newClipId,
        start: startTime,
        length: restClip.length || 3,
        meta: {
          ...meta,
          start: 'number',
          length: 'number',
        },
      };

      startTime += clipProperties.length;

      set(clipIdsAtom, (currentState) => [...currentState, newClipId]);
      set(assetIdsAtom, (currentState) => [...currentState, newAssetId]);

      set(clipsAtomFamily(newClipId), clipProperties);
      set(clipsTracksAtomFamily(newClipId), { trackId: toTrackId, trackIndex: 0 });

      return newClipId;
    });

    return { ids: newClipIds };
  };
};

const deleteTrackState = (callbackArgs) => {
  const { set, reset, snapshot } = callbackArgs;
  return (trackId) => {
    const trackClipIds = snapshot.getLoadable(trackClipIdsSelectorFamily(trackId)).contents;

    set(clipIdsAtom, (currentState) => {
      const newClipIds = currentState.filter((clipId) => !trackClipIds.includes(clipId));
      return newClipIds;
    });

    trackClipIds.forEach((clipId) => {
      reset(clipsAtomFamily(clipId));
      reset(clipsTracksAtomFamily(clipId));
    });

    const trackIds = snapshot.getLoadable(trackIdsAtom).contents;
    const trackIndex = trackIds.indexOf(trackId);
    const newTrackIds = [...trackIds.slice(0, trackIndex), ...trackIds.slice(trackIndex + 1)];

    set(trackIdsAtom, newTrackIds);

    const shuffledTracks = newTrackIds.slice(trackIndex, newTrackIds.length);
    shuffledTracks.forEach((shuffledTrackId) => {
      const shuffledTrackIndex = newTrackIds.indexOf(shuffledTrackId);
      const shuffledTrackClipIds = snapshot.getLoadable(trackClipIdsSelectorFamily(shuffledTrackId)).contents;

      shuffledTrackClipIds.forEach((shuffledTrackClipId) => {
        set(clipsTracksAtomFamily(shuffledTrackClipId), { trackId: shuffledTrackId, trackIndex: shuffledTrackIndex });
      });
    });
  };
};

const deleteClipState = (callbackArgs) => {
  const { set, snapshot, reset } = callbackArgs;
  return (id) => {
    const activeClipId = snapshot.getLoadable(activeClipAtom).contents;
    const clipId = id || activeClipId;

    set(clipIdsAtom, (currentState) => currentState.filter((id) => id !== clipId));

    reset(clipsAtomFamily(clipId));
    reset(clipsTracksAtomFamily(clipId));
    reset(activeClipAtom);
  };
};

const deleteClipOrTrackState = (callbackArgs) => {
  const { snapshot, reset, refresh } = callbackArgs;
  return () => {
    const activeClipId = snapshot.getLoadable(activeClipAtom).contents;
    if (activeClipId) {
      const deleteClip = deleteClipState(callbackArgs);
      deleteClip(activeClipId);
      reset(activeClipAtom);
      refresh(templateMediaSelectorFamily('overlay'));
      refresh(templateMediaSelectorFamily('mask'));
      refresh(templateMediaSelectorFamily('media'));
      return;
    }

    const activeTrackId = snapshot.getLoadable(activeTrackAtom).contents;
    if (activeTrackId) {
      const deleteTrack = deleteTrackState(callbackArgs);
      deleteTrack(activeTrackId);
      reset(activeTrackAtom);
      refresh(templateMediaSelectorFamily('overlay'));
      refresh(templateMediaSelectorFamily('mask'));
      refresh(templateMediaSelectorFamily('media'));
    }
  };
};

const copyClipIdState = atom({
  key: 'copyClipIdState',
  default: null,
});

const copyClipState = (callbackArgs) => {
  const { set, snapshot } = callbackArgs;
  return () => {
    const activeClipId = snapshot.getLoadable(activeClipAtom).contents;
    set(copyClipIdState, activeClipId);
  };
};

const pasteClipState = (callbackArgs) => {
  const { snapshot } = callbackArgs;
  const addClip = addClipState(callbackArgs);
  const copyPasteClipKeyframes = copyPasteClipKeyframesCallback(callbackArgs);
  return () => {
    const copyClipId = snapshot.getLoadable(copyClipIdState).contents;
    const clip = snapshot.getLoadable(clipSelectorFamily(copyClipId)).contents;
    const toTrackId = snapshot.getLoadable(activeTrackAtom).contents;
    const newClip = addClip({ toTrackId, clip });
    copyPasteClipKeyframes(copyClipId, newClip.id);
  };
};
export const useMoveClipState = () => {
  return useRecoilCallback(
    ({ set, snapshot }) =>
      (clipId, newStartTime, toTrackId, toTrackIndex) => {
        const { trackId: fromTrackId } = snapshot.getLoadable(clipsTracksAtomFamily(clipId)).contents;
        const originalClip = snapshot.getLoadable(clipSelectorFamily(clipId)).contents;

        const newClip = {
          ...originalClip,
          start: newStartTime,
          meta: { ...originalClip.meta, start: 'number', length: 'number' },
        };

        // TODO: refactor this, maybe it's own callback?
        if (ASSET_TYPES_MASK.includes(newClip['asset:type'])) {
          set(clipsTracksAtomFamily(clipId), { trackId: toTrackId, trackIndex: toTrackIndex });
          set(clipsAtomFamily(clipId), newClip);
          if (fromTrackId !== toTrackId) {
            set(trackMaskVisibleAtomFamily(toTrackId), true);
          }
          return;
        }

        // get all clips in track we're moving clip to
        const toTrackClips = snapshot
          .getLoadable(trackClipIdsSelectorFamily(toTrackId))
          .contents.map((id) => ({
            id,
            ...snapshot.getLoadable(clipSelectorFamily(id)).contents,
          }))
          .filter((f) => !ASSET_TYPES_MASK.includes(f['asset:type']) && f.id !== clipId);

        // get all clips in track we're moving clip to
        const fromTrackClips = snapshot
          .getLoadable(trackClipIdsSelectorFamily(fromTrackId))
          .contents.map((id) => ({
            id,
            ...snapshot.getLoadable(clipSelectorFamily(id)).contents,
          }))
          .filter((f) => !ASSET_TYPES_MASK.includes(f['asset:type']) && f.id !== clipId);

        // update the new clip's start time and get the gap between any overlapping clips
        let diff = 0;
        const { intersecting, complements } = getClipsGroupedByIntersect(toTrackClips, newClip);
        if (intersecting.length > 1) {
          const { newStart, space } = multipleIntersectingClips({ intersecting, newClip });
          newClip.start = roundToPrecision(newStart);
          diff = space;
        } else if (intersecting.length === 1) {
          const { newStart, space } = singleIntersectingClip({ intersecting, complements, newClip });
          newClip.start = roundToPrecision(newStart);
          diff = space || diff;
        }

        // update toTrackClips with new start
        let prevClipEnd = newClip.start + newClip.length;
        toTrackClips
          .filter((clip) => clip.start >= newClip.start)
          .forEach((clip) => {
            set(clipsAtomFamily(clip.id), (prevState) => {
              const update = {
                start: prevState?.meta?.start === 'auto' ? prevClipEnd : roundToPrecision(prevState.start + diff),
              };
              if (prevState?.meta?.start === 'auto') {
                prevClipEnd += prevState.length;
              }
              return {
                ...prevState,
                ...update,
              };
            });
          });

        set(clipsAtomFamily(clipId), newClip);
        if (fromTrackId !== toTrackId) {
          set(clipsTracksAtomFamily(clipId), { trackId: toTrackId, trackIndex: toTrackIndex });

          const [nextClipToTrack] = toTrackClips.filter((clip) => clip.start > newClip.start);
          const [nextClipFromTrack] = fromTrackClips.filter((clip) => clip.start > originalClip.start);
          const clipsToUpdate = [nextClipToTrack, nextClipFromTrack];

          clipsToUpdate.filter(Boolean).forEach((clip) => {
            set(clipsAtomFamily(clip.id), (prevState) => ({
              ...prevState,
              meta: { ...prevState.meta, start: 'number' },
            }));
          });
        }

        return newClip;
      },
    []
  );
};

export const useResetClipState = () => {
  return useRecoilCallback(
    ({ set, snapshot }) =>
      (clipId, toTrackId) => {
        const toTrackIndex = snapshot.getLoadable(trackIdsAtom).contents.findIndex((id) => id === toTrackId);
        set(clipsTracksAtomFamily(clipId), { trackId: -1, trackIndex: -1 });
        // hack to trick react into re-rendering the clip
        const resetClipTimeout = setTimeout(() => {
          clearTimeout(resetClipTimeout);
          set(clipsTracksAtomFamily(clipId), { trackId: toTrackId, trackIndex: toTrackIndex });
        }, 10);
      },
    []
  );
};

export const useMoveTrackState = () => {
  return useRecoilCallback(
    ({ set, snapshot }) =>
      (fromIndex, toIndex) => {
        // move tracks and cache new trackIds
        const trackIds = snapshot.getLoadable(trackIdsAtom).contents;
        const newTrackIds = [...trackIds];
        const [movedTrack] = newTrackIds.splice(fromIndex, 1);
        newTrackIds.splice(toIndex, 0, movedTrack);
        set(trackIdsAtom, newTrackIds);

        // update each clip's trackIndex to match new trackIds
        const clipIds = snapshot.getLoadable(clipIdsAtom).contents;
        clipIds.forEach((clipId) => {
          const { trackId: clipTrackId } = snapshot.getLoadable(clipsTracksAtomFamily(clipId)).contents;
          const clipTrackIndex = newTrackIds.findIndex((id) => id === clipTrackId);
          set(clipsTracksAtomFamily(clipId), (currentState) => ({ ...currentState, trackIndex: clipTrackIndex }));
        });
      },
    []
  );
};
const toggleAllTrackVisibility = (callbackArgs) => {
  const { set, snapshot } = callbackArgs;
  return () => {
    const trackIds = snapshot.getLoadable(trackIdsAtom).contents;
    const allVisible = trackIds.every((trackId) => snapshot.getLoadable(trackVisibleAtomFamily(trackId)).contents);
    trackIds.forEach((trackId) => {
      set(trackVisibleAtomFamily(trackId), !allVisible);
    });
  };
};

const toggleAllTrackLocked = (callbackArgs) => {
  const { set, snapshot } = callbackArgs;
  return () => {
    const trackIds = snapshot.getLoadable(trackIdsAtom).contents;
    const allLocked = trackIds.every((trackId) => snapshot.getLoadable(trackLockedAtomFamily(trackId)).contents);
    trackIds.forEach((trackId) => {
      set(trackLockedAtomFamily(trackId), !allLocked);
    });
  };
};

export const useAddTrackState = () => useRecoilCallback(addTrackState);
export const useAddClipState = () => useRecoilCallback(addClipState);
export const useCopyClipState = () => useRecoilCallback(copyClipState);
export const usePasteClipState = () => useRecoilCallback(pasteClipState);
export const useDeleteClipState = () => useRecoilCallback(deleteClipState);
export const useDeleteTrackState = () => useRecoilCallback(deleteTrackState);
export const useDeleteClipOrTrackState = () => useRecoilCallback(deleteClipOrTrackState);
export const useToggleAllTrackVisibilityCallback = () => useRecoilCallback(toggleAllTrackVisibility);
export const useToggleAllTrackLockedCallback = () => useRecoilCallback(toggleAllTrackLocked);
