import { Container } from '@inlet/react-pixi';
import { ImageResource } from 'pixi.js';
import { useCallback, useEffect, useState } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';

import CanvasImagePlayer from '@feature/studio/canvas/CanvasImagePlayer';

import { clipErrorsAtomFamily, overridesAtomFamily } from '@store/Edit';
import { clipSelectorFamily } from '@store/EditSelectors';

function CanvasImage({ id, index }) {
  const { ['asset:src']: src } = useRecoilValue(clipSelectorFamily(id));
  const assetOverrides = useRecoilValue(overridesAtomFamily(id));
  const setClipErrors = useSetRecoilState(clipErrorsAtomFamily(id));
  const [resourceLoadError, setResourceLoadError] = useState(null);
  const [resource, setResource] = useState(null);

  const loadImage = useCallback(async () => {
    if (!src) {
      setResource(null);
      return;
    }

    setResourceLoadError(false);

    try {
      const loadedResource = await new ImageResource(src).load();
      if (!loadedResource) throw new Error(`Failed to load image. ${src}`);

      setResource(loadedResource);
    } catch (error) {
      console.error(error);
      setResourceLoadError(true);
      setResource(null);
    }
  }, [src]);

  useEffect(() => {
    loadImage();
  }, [loadImage]);

  useEffect(() => {
    const errors = [];

    if (resourceLoadError) {
      errors.push({
        title: 'Preview Unavailable',
        detail: `Asset source is inaccessible.${
          assetOverrides.src ? ` The merge field ${assetOverrides.src} cannot be displayed.` : ''
        }`,
      });
    }

    setClipErrors(errors);
  }, [resourceLoadError]);

  if (!resource) {
    return <Container />;
  }

  return <CanvasImagePlayer id={id} index={index} resource={resource} />;
}

export default CanvasImage;
