import { Offcanvas } from 'react-bootstrap';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';

import { IconCollectionPlay } from '@assets/icons';

import Thumbnail from '@feature/studio/render/RenderPreview';
import ThumbnailStatus from '@feature/studio/render/RenderPreviewStatus';

import EmptyState from '@components/atoms/EmptyState';

import { renderSubmittedAtom, rendersAtom, rendersPanelAtom } from '@store/Renders';
import { errorAtom } from '@store/UI';

function PanelRenders() {
  const renders = useRecoilValue(rendersAtom);
  const submitted = useRecoilValue(renderSubmittedAtom);
  const [showPanel, setShowPanel] = useRecoilState(rendersPanelAtom);
  const setErrorMessage = useSetRecoilState(errorAtom);
  const handleClose = () => setShowPanel(false);
  // const handleShow = () => setShowPanel(true);

  const handleErrorMessageChange = (message) => {
    setErrorMessage({ message });
  };

  return (
    <Offcanvas show={showPanel} onHide={handleClose} placement="end" style={{ width: '600px' }}>
      <Offcanvas.Header closeButton>
        <Offcanvas.Title>Renders</Offcanvas.Title>
      </Offcanvas.Header>
      <Offcanvas.Body>
        {submitted && <ThumbnailStatus status="submitted" />}
        {renders.map((render) => (
          <Thumbnail key={render.id} renderData={render} handleErrors={handleErrorMessageChange} />
        ))}
        {!submitted && renders.length === 0 && (
          <EmptyState Icon={IconCollectionPlay}>
            <h5>Rendered media files will appear here</h5>
          </EmptyState>
        )}
      </Offcanvas.Body>
    </Offcanvas>
  );
}

export default PanelRenders;
