import { useEffect } from 'react';
import { useRecoilState, useRecoilValue, useResetRecoilState, useSetRecoilState } from 'recoil';

import config from '@src/config';

import { getOwner } from '@api/services/sdk';
import { transformGalleryTemplate } from '@api/services/template';

import { useAnalytics } from '@components/analytics/Analytics';

import { assetLoaderIsLoadingAtom } from '@store/Assets';
import { activeClipAtom } from '@store/Clips';
import { useHydrateStoreFromTemplate } from '@store/Edit';
import { derivedJsonSelectorFamily } from '@store/EditSelectors';
import {
  sdkCustomerAtom,
  sdkEnabledAtom,
  sdkLoadingAtom,
  sdkOptionsAtom,
  sdkOptionsSelector,
  sdkOriginAtom,
} from '@store/Sdk';
import { templateLoadingAtom } from '@store/Template';
import { errorAtom } from '@store/UI';

import useFingerprint from '@hooks/useFingerprint';

import { sendMessage } from '@utils/iframe';
import { isValidUrl } from '@utils/isValidLink';

const SDK_VERSION = config.sdkVersion;

const parseSdkOptions = (options) => {
  const parsedOptions = {
    isSidepanel: Boolean(options.sidepanel),
    isInteractive: Boolean(options.interactive),
    isTimeline: Boolean(options.timeline),
    isSettings: Boolean(options.settings),
    isControls: Boolean(options.controls),
    isLogoUrl: options.style?.logo?.url,
    primaryColor: options.style?.colors?.primaryColor,
    secondaryColor: options.style?.colors?.secondaryColor,
    stylesheet: options.style?.stylesheet,
  };

  return Object.fromEntries(
    // eslint-disable-next-line no-unused-vars
    Object.entries(parsedOptions).filter(([_, value]) => value !== undefined && value !== null)
  );
};

const useSdkIntegration = () => {
  const sdkOptions = useRecoilValue(sdkOptionsSelector);
  const [sdkLoading, setSdkLoading] = useRecoilState(sdkLoadingAtom);
  const setSdkEnabled = useSetRecoilState(sdkEnabledAtom);
  const setSdkCustomer = useSetRecoilState(sdkCustomerAtom);
  const setSdkOrigin = useSetRecoilState(sdkOriginAtom);
  const setSdkOptions = useSetRecoilState(sdkOptionsAtom);
  const templateJson = useRecoilValue(derivedJsonSelectorFamily());
  const setErrorMessage = useSetRecoilState(errorAtom);
  const hydrateStore = useHydrateStoreFromTemplate();
  const setTemplateLoading = useSetRecoilState(templateLoadingAtom);
  const setAssetsLoading = useSetRecoilState(assetLoaderIsLoadingAtom);
  const resetActiveClip = useResetRecoilState(activeClipAtom);
  const { identifyUser, trackEvent } = useAnalytics();
  const { visitorId } = useFingerprint();

  const fetchOwnerPlan = async (owner) => {
    const plan = await getOwner(owner);
    if (['basic', 'plus', 'enterprise'].includes(plan)) {
      setSdkCustomer(true);
    }
  };

  const rehydrateStudio = async (data) => {
    setTemplateLoading(true);
    setAssetsLoading(true);
    try {
      const template = await transformGalleryTemplate({ template: data });
      hydrateStore(template?.template);
      resetActiveClip();
      return template;
    } catch (error) {
      console.error(error);
      setErrorMessage({ message: error.message });
    } finally {
      setTemplateLoading(false);
    }
  };

  const handleSdkMessage = async (data) => {
    const { version, operationType, origin, template, options } = data;
    if (version && version !== SDK_VERSION && !operationType) {
      return;
    }

    if (origin !== undefined) {
      setSdkOrigin(origin);
    }

    switch (operationType) {
      case 'LOAD_TEMPLATE': {
        trackEvent('Load Template', template);
        if (template) {
          await rehydrateStudio(template);
          const parsedJson = JSON.parse(templateJson);
          sendMessage(parsedJson, sdkOptions.origin);
        }
        break;
      }

      case 'CREATE_STUDIO_INSTANCE': {
        identifyUser(visitorId, options);
        trackEvent('Create Studio Instance', options);

        if (options?.owner) {
          await fetchOwnerPlan(options.owner);
          setSdkEnabled(true);
          setSdkOptions((prevState) => ({ ...prevState, ...parseSdkOptions(options) }));
        }

        if (template && typeof template === 'object') {
          sendMessage(template, sdkOptions.origin);
          rehydrateStudio(template);
        }
        setSdkLoading(false);
        break;
      }

      case 'REFRESH_STUDIO_INSTANCE': {
        trackEvent('Refresh Studio Instance', options);
        if (Object.keys(options).length > 0) {
          await fetchOwnerPlan(options.owner);
          setSdkOptions((prevState) => ({ ...prevState, ...parseSdkOptions(options) }));
        }
        break;
      }

      default: {
        return;
      }
    }
  };

  useEffect(() => {
    const linkId = 'sdk-custom-css';
    const existingLink = document.getElementById(linkId);

    if (!existingLink) {
      const link = document.createElement('link');
      link.rel = 'stylesheet';
      link.id = linkId;

      if (sdkOptions.stylesheet && isValidUrl(sdkOptions.stylesheet)) {
        link.href = sdkOptions.stylesheet;
        document.head.appendChild(link);
      } else {
        return;
      }
    } else {
      if (sdkOptions.stylesheet) {
        existingLink.href = sdkOptions.stylesheet;
      }
    }

    return () => {
      const existingLink = document.getElementById(linkId);
      if (existingLink) {
        document.head.removeChild(existingLink);
      }
    };
  }, [sdkOptions.stylesheet]);

  useEffect(() => {
    if (sdkOptions.origin) {
      const parsedJson = JSON.parse(templateJson);
      sendMessage(parsedJson, sdkOptions.origin);
    }
  }, [templateJson, sdkOptions.origin]);

  useEffect(() => {
    document.documentElement.style.setProperty('--primary', sdkOptions.primaryColor);
    document.documentElement.style.setProperty('--secondary-colors-lilac', sdkOptions.secondaryColor);
    document.documentElement.style.setProperty('--asset-color-text', sdkOptions.primaryColor);
    document.documentElement.style.setProperty('--asset-color-image', sdkOptions.secondaryColor);
    document.documentElement.style.setProperty('--asset-color-video', sdkOptions.secondaryColor);
    document.documentElement.style.setProperty('--asset-color-audio', sdkOptions.secondaryColor);
    document.documentElement.style.setProperty('--asset-color-mask', sdkOptions.secondaryColor);
  }, [sdkOptions.primaryColor, sdkOptions.secondaryColor]);

  useEffect(() => {
    const handleMessage = async (event) => {
      try {
        await handleSdkMessage(event.data);
      } catch (error) {
        console.error('Error parsing message data:', error);
      }
    };

    window.addEventListener('message', handleMessage);

    return () => {
      window.removeEventListener('message', handleMessage);
    };
  }, [handleSdkMessage]);

  return { sdkLoading };
};

export default useSdkIntegration;
