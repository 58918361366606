import { useRef } from 'react';
import { ButtonGroup, Col, InputGroup, OverlayTrigger, Row, ToggleButton, Tooltip } from 'react-bootstrap';
import AsyncSelect from 'react-select/async';
import { useRecoilState, useRecoilValueLoadable } from 'recoil';

import {
  IconAlignBottom,
  IconAlignCenterVertically,
  IconAlignTop,
  IconBorderRadius,
  IconFontSize,
  IconFormatColorFill,
  IconFormatColorReset,
  IconFormatColorText,
  IconLineHeight,
  IconPadding,
  IconStroke,
  IconStrokeWidth,
  IconTextAlignCenter,
  IconTextAlignLeft,
  IconTextAlignRight,
  IconTransparent,
} from '@assets/icons';

import OffsetFields from '@feature/studio/fields/OffsetFields';
import TimingFields from '@feature/studio/fields/TimingFields';
import TransformFields from '@feature/studio/fields/TransformFields';
import VisualEffectsFields from '@feature/studio/fields/VisualEffectsFields';

import ColorPicker from '@components/atoms/ColorPicker';
import InputNumber from '@components/controls/InputNumber';
import InputRange from '@components/controls/InputRange';
import InputTextarea from '@components/controls/InputTextarea';
import OverrideMenu from '@components/mergeFields/OverrideMenu';

import { useAddFontState, usePurgeUnusedFontsState } from '@store/Edit';
import { clipSelectorFamily, fontListByCategorySelector } from '@store/EditSelectors';

import { COLOR, MAX_HEIGHT, MAX_WIDTH } from '@constants/TextAssetDefaults';

const MIN_FONT_SIZE = 1;

const assetPropertyTransform = (prop, type, value) => {
  if (type === 'number' && !prop.includes('font:size')) {
    return value ? parseFloat(value) : undefined;
  }

  const integerProps = [
    'asset:background:padding',
    'asset:background:opacity',
    'asset:background:borderRadius',
    'asset:stroke:width',
    'asset:font:opacity',
  ];

  if (integerProps.some((p) => prop.includes(p))) {
    return parseFloat(value);
  }

  return value;
};

const textAlignHorizontalOptions = [
  { value: 'left', icon: <IconTextAlignLeft size={18} /> },
  { value: 'center', icon: <IconTextAlignCenter size={18} /> },
  { value: 'right', icon: <IconTextAlignRight size={18} /> },
];

const textAlignVerticalOptions = [
  { value: 'top', icon: <IconAlignTop size={18} /> },
  { value: 'center', icon: <IconAlignCenterVertically size={18} /> },
  { value: 'bottom', icon: <IconAlignBottom size={18} /> },
];

function SettingAssetText({ id }) {
  const fontsListByCategory = useRecoilValueLoadable(fontListByCategorySelector);

  const [clip, setClip] = useRecoilState(clipSelectorFamily(id));
  const addFont = useAddFontState();
  const purgeUnusedFonts = usePurgeUnusedFontsState();

  const documentRef = useRef(document);

  const processSelectedFont = async (selectedOption) => {
    if (/https:\/\/fonts\.gstatic\.com\//.test(selectedOption.value)) {
      await purgeUnusedFonts();
      addFont({ src: selectedOption.value });

      const fontFace = new FontFace(selectedOption.family, `url(${selectedOption.value})`);
      try {
        const loadedFace = await fontFace.load();
        await loadedFace.loaded;
        documentRef.current.fonts.add(loadedFace);
      } catch (error) {
        console.error('Failed to load font:', error);
      }
    }
  };

  const handleFontFamilyUpdate = async (selectedOption) => {
    await processSelectedFont(selectedOption);
    setClip({ 'asset:font:family': selectedOption.family });
  };

  const handleAssetUpdate =
    ({ prop, value }) =>
    (event) => {
      const { type, value: eventValue } = event.target;
      setClip({ [prop]: assetPropertyTransform(prop, type, value ?? eventValue) });
    };

  const handleAssetMetaUpdate =
    ({ prop, value }) =>
    (event) => {
      const { type, value: eventValue } = event.target;
      setClip((prevClip) => ({
        ...prevClip,
        'asset:meta': { ...prevClip['asset:meta'], [prop]: assetPropertyTransform(prop, type, value ?? eventValue) },
      }));
    };

  const handleEmptyFontSize = (event) => {
    if (!event.target.value) {
      setClip({ 'font:size': MIN_FONT_SIZE });
    }
  };

  const loadFontOptions = async (inputValue) => {
    const data = await fontsListByCategory.contents;
    const { uploaded = [], default: defaultFonts = [], google = [] } = data;
    const allFonts = [...uploaded, ...defaultFonts, ...google];
    const filteredFonts = allFonts.filter((font) => font.label.toLowerCase().includes(inputValue.toLowerCase()));

    return [
      { label: 'Uploaded Fonts', options: filteredFonts.filter((font) => uploaded.includes(font)) },
      { label: 'Default Fonts', options: filteredFonts.filter((font) => defaultFonts.includes(font)) },
      { label: 'Google Fonts', options: filteredFonts.filter((font) => google.includes(font)) },
    ].filter((group) => group.options.length > 0);
  };

  return (
    <>
      <div className="html-input-wrapper mb-10">
        <div className="text-content">
          <InputTextarea
            id="text"
            placeholder="{{ MY_VARIABLE }}"
            rows={5}
            value={clip['asset:meta'].text}
            handleChange={handleAssetMetaUpdate({ prop: 'text' })}
          />
        </div>
      </div>

      <h6>Style</h6>
      <div className="mb-10">
        <Row className="mb-2">
          <Col md={12} className="d-flex align-items-center justify-content-between gap-2">
            <AsyncSelect
              cacheOptions
              defaultOptions
              loadOptions={loadFontOptions}
              onChange={handleFontFamilyUpdate}
              placeholder="Select or type a font"
              className="w-100"
              value={{
                value: clip['asset:font:family'],
                label: clip['asset:font:family'],
                family: clip['asset:font:family'],
              }}
              styles={{
                option: (provided, state) => ({
                  ...provided,
                  fontFamily: state.data.family,
                }),
                singleValue: (provided, state) => ({
                  ...provided,
                  fontFamily: state.data.family,
                }),
              }}
            />

            <OverrideMenu path="asset:font:family" />
          </Col>
        </Row>

        <Row className="mb-2">
          <Col md={6} className="text-size d-flex align-items-center justify-content-between gap-2 mb-2">
            <InputNumber
              id="fontSize"
              title="Font Size"
              handleBlur={handleEmptyFontSize}
              handleChange={handleAssetUpdate({ prop: 'asset:font:size' })}
              prefix={<IconFontSize />}
              min={1}
              suffix="px"
              value={clip['asset:font:size']}
            />
            <OverrideMenu path="asset:font:size" />
          </Col>
          <Col md={6} className="d-flex align-items-center justify-content-between gap-2 mb-2">
            <InputNumber
              id="lineHeight"
              title="Line Height"
              placeholder="1.2"
              min={0}
              max={10}
              step={0.01}
              handleChange={handleAssetUpdate({ prop: 'asset:font:lineHeight' })}
              prefix={<IconLineHeight size={18} />}
              value={clip['asset:font:lineHeight']}
            />
            <OverrideMenu path="asset:font:lineHeight" />
          </Col>
        </Row>

        <Row className="mb-2">
          <Col md={6} className="d-flex align-items-center justify-content-between gap-2">
            <ButtonGroup>
              {textAlignHorizontalOptions.map(({ value, icon }) => (
                <ToggleButton
                  key={value}
                  id={`textAlignHorizontal-${value}`}
                  type="radio"
                  name="alignment:horizontal"
                  value={value}
                  checked={clip['asset:alignment:horizontal'] === value}
                  onChange={handleAssetUpdate({ prop: 'asset:alignment:horizontal', value })}
                >
                  {icon}
                </ToggleButton>
              ))}
            </ButtonGroup>
            <OverrideMenu path="asset:alignment:horizontal" />
          </Col>

          <Col md={6} className="d-flex align-items-center justify-content-between gap-2">
            <ButtonGroup>
              {textAlignVerticalOptions.map(({ value, icon }) => (
                <ToggleButton
                  key={value}
                  id={`textAlignVertical-${value}`}
                  type="radio"
                  name="alignment:vertical"
                  value={value}
                  checked={
                    clip['asset:alignment:vertical'] === value ||
                    (!clip['asset:alignment:vertical'] && value === 'center')
                  }
                  onChange={handleAssetUpdate({ prop: 'asset:alignment:vertical', value })}
                >
                  {icon}
                </ToggleButton>
              ))}
            </ButtonGroup>
            <OverrideMenu path="asset:alignment:vertical" />
          </Col>
        </Row>

        <VisualEffectsFields id={id} />
      </div>

      <h6>Color</h6>
      <div className="mb-10">
        <Row className="mb-2">
          <Col sm={12} className="d-flex align-items-center justify-content-between gap-2 mb-2">
            <InputGroup>
              <OverlayTrigger placement="top" overlay={<Tooltip id={`tooltip-font-color`}>Font Color</Tooltip>}>
                <InputGroup.Text className="prefix">
                  <IconFormatColorText size={18} />
                </InputGroup.Text>
              </OverlayTrigger>

              <ColorPicker
                className="color"
                value={clip['asset:font:color'] || COLOR}
                handleEvent={handleAssetUpdate({ prop: 'asset:font:color' })}
              />
            </InputGroup>
            <OverrideMenu path="asset:font:color" />
          </Col>
          <Col className="d-flex align-items-center justify-content-between gap-2">
            <InputRange
              id="font-opacity"
              title="Font Opacity"
              value={clip['asset:font:opacity'] >= 0 ? clip['asset:font:opacity'] : 1}
              placeholder="1"
              min={0}
              max={1}
              step={0.01}
              prefix={<IconTransparent size={18} />}
              handleChange={handleAssetUpdate({ prop: 'asset:font:opacity' })}
            />
            <OverrideMenu path="asset:font:opacity" />
          </Col>
        </Row>
      </div>

      <h6>Stroke</h6>
      <div className="mb-10">
        <Row className="mb-2">
          <Col sm={12} className="d-flex align-items-center justify-content-between gap-2 mb-2">
            <InputGroup>
              <OverlayTrigger placement="top" overlay={<Tooltip id={`tooltip-stroke-color`}>Stroke Color</Tooltip>}>
                <InputGroup.Text className="prefix">
                  <IconStroke size={18} />
                </InputGroup.Text>
              </OverlayTrigger>

              <ColorPicker
                className="color"
                value={clip['asset:stroke:color'] || 'transparent'}
                handleEvent={handleAssetUpdate({ prop: 'asset:stroke:color' })}
              />
            </InputGroup>
            <OverrideMenu path="asset:stroke:color" />
          </Col>
          <Col className="d-flex align-items-center justify-content-between gap-2">
            <InputRange
              id="stroke-width"
              title="Stroke Width"
              value={clip['asset:stroke:width'] || 0}
              placeholder="0"
              min={0}
              max={5}
              step={1}
              prefix={<IconStrokeWidth size={18} />}
              handleChange={handleAssetUpdate({ prop: 'asset:stroke:width' })}
            />
            <OverrideMenu path="asset:stroke:width" />
          </Col>
        </Row>
      </div>

      <h6>Background</h6>
      <div className="mb-10">
        <Row className="mb-2">
          <Col sm={12} className="d-flex align-items-center justify-content-between gap-2 mb-2">
            <InputGroup>
              <InputGroup.Text className="prefix">
                <OverlayTrigger
                  placement="top"
                  overlay={<Tooltip id={`tooltip-background-color`}>Background Color</Tooltip>}
                >
                  <button
                    type="button"
                    name="asset:background:color"
                    className="unstyled"
                    disabled={!clip['asset:background:color']}
                    onClick={handleAssetUpdate({ prop: 'asset:background:color' })}
                  >
                    {clip['asset:background:color'] ? (
                      <IconFormatColorReset size={18} title="Transparent Background Color" />
                    ) : (
                      <IconFormatColorFill size={18} title="Background Color" />
                    )}
                  </button>
                </OverlayTrigger>
              </InputGroup.Text>
              <ColorPicker
                name="asset:background:color"
                className="background"
                value={clip['asset:background:color']}
                handleEvent={handleAssetUpdate({ prop: 'asset:background:color' })}
              />
            </InputGroup>
            <OverrideMenu path="asset:background:color" />
          </Col>
          <Col sm={12} className="d-flex align-items-center justify-content-between gap-2 mb-2">
            <InputRange
              id="background-opacity"
              title="Background Opacity"
              value={clip['asset:background:opacity'] >= 0 ? clip['asset:background:opacity'] : 1}
              placeholder="1"
              min={0}
              max={1}
              step={0.01}
              prefix={<IconTransparent size={18} />}
              handleChange={handleAssetUpdate({ prop: 'asset:background:opacity' })}
            />
            <OverrideMenu path="asset:background:opacity" />
          </Col>
          <Col sm={12} className="d-flex align-items-center justify-content-between gap-2 mb-2">
            <InputRange
              id="background-border-radius"
              title="Background Radius"
              value={clip['asset:background:borderRadius'] || 0}
              placeholder="0"
              min={0}
              max={100}
              step={1}
              prefix={<IconBorderRadius size={18} />}
              handleChange={handleAssetUpdate({ prop: 'asset:background:borderRadius' })}
            />
            <OverrideMenu path="asset:background:borderRadius" />
          </Col>
          <Col className="d-flex align-items-center justify-content-between gap-2">
            <InputRange
              id="background-padding"
              title="Background Padding"
              value={clip['asset:background:padding']}
              min={0}
              step={1}
              suffix="px"
              placeholder="0"
              prefix={<IconPadding size={18} />}
              handleChange={handleAssetUpdate({ prop: 'asset:background:padding' })}
            />
            <OverrideMenu path="asset:background:padding" />
          </Col>
        </Row>

        <Row className="mb-2"></Row>
      </div>

      <h6>Size &amp; Position</h6>

      <div className="mb-10">
        <Row className="mb-2">
          <Col className="d-flex align-items-center justify-content-between gap-2">
            <InputNumber
              id="width"
              name="width"
              title="Width (px)"
              value={clip['asset:width']}
              min={0}
              max={MAX_WIDTH}
              step={1}
              prefix="W"
              placeholder="0"
              handleChange={handleAssetUpdate({ prop: 'asset:width' })}
            />
            <OverrideMenu path="asset:width" />
          </Col>
          <Col className="d-flex align-items-center justify-content-between gap-2">
            <InputNumber
              id="height"
              name="height"
              title="Height (px)"
              value={clip['asset:height']}
              min={0}
              max={MAX_HEIGHT}
              step={1}
              prefix="H"
              placeholder="0"
              handleChange={handleAssetUpdate({ prop: 'asset:height' })}
            />
            <OverrideMenu path="asset:height" />
          </Col>
        </Row>

        <OffsetFields id={id} />
      </div>

      <h6>Transform</h6>
      <div className="mb-10">
        <TransformFields id={id} />
      </div>

      <h6>Timing</h6>
      <TimingFields id={id} />
    </>
  );
}

export default SettingAssetText;
