import { useCallback, useEffect, useState } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';

import { activeClipAtom } from '@store/Clips';
import { isKeyframesTabSelector } from '@store/Edit';
import { clipSelectorFamily } from '@store/EditSelectors';
import {
  activeClipKeyframeIdSelectorFamily,
  keyframeOffsetXAtomFamily,
  keyframeOffsetYAtomFamily,
  keyframeRotateAtomFamily,
  keyframeScaleAtomFamily,
  useAddClipKeyframe,
} from '@store/Keyframes';
import { studioActiveTabAtom } from '@store/UI';

import {
  calculateAngle,
  calculateDimensions,
  calculatePosition,
  calculateResize,
  calculateScale,
} from '@utils/editor/assetCalculations';
import calculateHandlePosition from '@utils/editor/editFrameCalculations';
import setPrecision from '@utils/math/setPrecision';
import { getPanelType } from '@utils/studio/sidebar';

const AUTO_ADD_KEYFRAME_ENABLED = false;

const snapPosition = (position, center, size, canvasSize, angle, threshold = 10) => {
  let newPosition = position;
  let newAngle = angle;
  let snappingLines = { x: false, y: false };

  if (Math.abs(position.x - center.x) < threshold) {
    newPosition.x = center.x;
    snappingLines.x = true;
  } else if (Math.abs(position.x - size.width / 2) < threshold) {
    newPosition.x = size.width / 2;
  } else if (Math.abs(position.x - (canvasSize.width - size.width / 2)) < threshold) {
    newPosition.x = canvasSize.width - size.width / 2;
  }

  if (Math.abs(position.y - center.y) < threshold) {
    newPosition.y = center.y;
    snappingLines.y = true;
  } else if (Math.abs(position.y - size.height / 2) < threshold) {
    newPosition.y = size.height / 2;
  } else if (Math.abs(position.y - (canvasSize.height - size.height / 2)) < threshold) {
    newPosition.y = canvasSize.height - size.height / 2;
  }

  if (Math.abs(angle % 45) < threshold) {
    newAngle = Math.round(angle / 45) * 45;
  }

  return { position: newPosition, angle: newAngle, snappingLines };
};

function useMovableAsset(params) {
  const { id, assetHeight, assetWidth, canvasHeight, canvasWidth, calculatedClip, spriteRef } = params;

  const setClip = useSetRecoilState(clipSelectorFamily(id));

  const [originalAssetWidth, setOriginalAssetWidth] = useState(assetWidth);
  const [originalAssetHeight, setOriginalAssetHeight] = useState(assetHeight);
  const [originalCanvasWidth, setOriginalCanvasWidth] = useState(canvasWidth);
  const [originalCanvasHeight, setOriginalCanvasHeight] = useState(canvasHeight);
  const [isMoving, setIsMoving] = useState(false);
  const [isScaling, setIsScaling] = useState(false);
  const [isRotating, setIsRotating] = useState(false);
  const [isResizing, setIsResizing] = useState(false);
  const [isGrabbing, setIsGrabbing] = useState(false);
  const [dragPosition, setDragPosition] = useState();
  const [fit, setFit] = useState(calculatedClip?.fit || 'crop');
  const [scale, setScale] = useState(calculatedClip?.scale || 1);
  const [angle, setAngle] = useState(calculatedClip['transform:rotate:angle'] || 0);

  const [dimensions, setDimensions] = useState(
    calculateDimensions({
      assetHeight,
      assetWidth,
      canvasHeight,
      canvasWidth,
      dimensions: calculatedClip['asset:width']
        ? { width: calculatedClip['asset:width'], height: calculatedClip['asset:height'] }
        : undefined,
      fit,
      scale: calculatedClip['asset:scale'] || 1,
    })
  );

  const [position, setPosition] = useState(
    calculatePosition({
      assetHeight,
      assetWidth,
      canvasHeight,
      canvasWidth,
      positionType: calculatedClip?.position || 'center',
      positionX: calculatedClip['offset:x'],
      positionY: calculatedClip['offset:y'],
      scale,
    })
  );

  const [snappingLines, setSnappingLines] = useState({
    x: false,
    y: false,
  });

  const setActiveClip = useSetRecoilState(activeClipAtom);
  const setActiveTab = useSetRecoilState(studioActiveTabAtom);
  const addClipKeyframe = useAddClipKeyframe(id);

  const activeClipKeyframeId = useRecoilValue(activeClipKeyframeIdSelectorFamily(id));
  const setKeyframeOffsetX = useSetRecoilState(keyframeOffsetXAtomFamily(activeClipKeyframeId));
  const setKeyframeOffsetY = useSetRecoilState(keyframeOffsetYAtomFamily(activeClipKeyframeId));
  const setKeyframeScale = useSetRecoilState(keyframeScaleAtomFamily(activeClipKeyframeId));
  const setKeyframeRotate = useSetRecoilState(keyframeRotateAtomFamily(activeClipKeyframeId));
  const isKeyframesTab = useRecoilValue(isKeyframesTabSelector);

  const [xMoveOrigin, setXMoveOrigin] = useState(0);
  const [yMoveOrigin, setYMoveOrigin] = useState(0);
  const [positionOrigin, setPositionOrigin] = useState(position);
  const [xResizeOrigin, setXResizeOrigin] = useState(0);
  const [yResizeOrigin, setYResizeOrigin] = useState(0);
  const [scaleOrigin, setScaleOrigin] = useState(scale);
  const [xScaleOrigin, setXScaleOrigin] = useState(0);
  const [yScaleOrigin, setYScaleOrigin] = useState(0);
  const [angleOrigin, setAngleOrigin] = useState(scale);
  const [dimensionsOrigin, setDimensionsOrigin] = useState(dimensions);

  const [bounds, setBounds] = useState(
    calculateHandlePosition({
      dimensions: { width: dimensions.width, height: dimensions.height },
      position: { x: position.x, y: position.y },
    })
  );

  const setAssetPosition = useCallback(() => {
    const updatedDimensions = calculateDimensions({
      assetHeight,
      assetWidth,
      canvasHeight,
      canvasWidth,
      fit: calculatedClip?.fit || 'crop',
      scale: calculatedClip?.scale || 1,
    });

    const updatedPosition = calculatePosition({
      assetHeight: updatedDimensions.height,
      assetWidth: updatedDimensions.width,
      canvasHeight,
      canvasWidth,
      positionType: calculatedClip?.position || 'center',
      positionX: calculatedClip['offset:x'],
      positionY: calculatedClip['offset:y'],
      scale: updatedDimensions.scale,
    });

    const handlePosition = calculateHandlePosition({
      dimensions: {
        width: updatedDimensions.width,
        height: updatedDimensions.height,
      },
      position: { x: updatedPosition.x, y: updatedPosition.y },
    });

    setDimensions(updatedDimensions);
    setPosition(updatedPosition);
    setBounds(handlePosition);
    setScale(calculatedClip?.scale || 1);
  }, [
    assetHeight,
    assetWidth,
    canvasHeight,
    canvasWidth,
    calculatedClip['offset:x'],
    calculatedClip['offset:y'],
    calculatedClip?.position,
    calculatedClip?.scale,
    calculatedClip?.fit,
    calculatedClip['asset:aspectRatio'],
  ]);

  useEffect(() => {
    if ((assetHeight && assetHeight !== originalAssetHeight) || (assetWidth && assetWidth !== originalAssetWidth)) {
      const updatedDimensions = calculateDimensions({
        assetHeight,
        assetWidth,
        canvasHeight,
        canvasWidth,
        fit,
        scale: calculatedClip?.scale || 1,
      });

      const handlePosition = calculateHandlePosition({
        dimensions: {
          width: updatedDimensions.width,
          height: updatedDimensions.height,
        },
        position: { x: position.x, y: position.y },
      });

      setDimensions(updatedDimensions);
      setBounds(handlePosition);
      setScale(calculatedClip?.scale || 1);
      setOriginalAssetWidth(assetWidth);
      setOriginalAssetHeight(assetHeight);
    }
  }, [assetWidth, assetHeight, originalAssetWidth, originalAssetHeight]);

  useEffect(() => {
    if (
      (canvasWidth > 0 && canvasWidth !== originalCanvasWidth) ||
      (canvasHeight > 0 && canvasHeight !== originalCanvasHeight)
    ) {
      setAssetPosition();
      setOriginalCanvasWidth(canvasWidth);
      setOriginalCanvasHeight(canvasHeight);
    }
  }, [canvasWidth, canvasHeight, originalCanvasHeight, originalCanvasWidth]);

  useEffect(() => {
    if (calculatedClip?.scale && calculatedClip?.scale !== scale) {
      setAssetPosition();
      setScale(calculatedClip.scale);
    }
  }, [calculatedClip.scale]);

  useEffect(() => {
    setAngle(calculatedClip['transform:rotate:angle'] || 0);
  }, [calculatedClip['transform:rotate:angle']]);

  useEffect(() => {
    setAssetPosition();
  }, [calculatedClip['offset:x'], calculatedClip['offset:y'], calculatedClip['asset:aspectRatio']]);

  useEffect(() => {
    setAssetPosition();
    setFit(calculatedClip.fit || 'crop');
  }, [calculatedClip.fit]);

  const handlePositionGrab = (event) => {
    const mousePosition = event.data.getLocalPosition(spriteRef.current.parent);
    setXMoveOrigin(spriteRef.current.x + mousePosition.x - position.x);
    setYMoveOrigin(spriteRef.current.y + mousePosition.y - position.y);
    setPositionOrigin({ ...position });
    setIsMoving(true);
    setIsGrabbing(true);
    setActiveClip(id);
    setActiveTab(getPanelType(calculatedClip['asset:type']));
  };

  const handlePositionDrag = (event) => {
    if (isMoving) {
      const mousePosition = event.data.getLocalPosition(spriteRef.current.parent);
      const updatedPosition = {
        x: mousePosition.x - xMoveOrigin + positionOrigin.x,
        y: mousePosition.y - yMoveOrigin + positionOrigin.y,
      };

      const center = { x: canvasWidth / 2, y: canvasHeight / 2 };
      const size = { width: dimensions.width, height: dimensions.height };
      const canvasSize = { width: canvasWidth, height: canvasHeight };

      const { position: snappedPosition, snappingLines: newSnappingLines } = snapPosition(
        updatedPosition,
        center,
        size,
        canvasSize,
        angle
      );

      setSnappingLines(newSnappingLines);

      setPosition(snappedPosition);

      const handlePosition = calculateHandlePosition({
        dimensions: {
          width: dimensions.width,
          height: dimensions.height,
        },
        position: { x: updatedPosition.x, y: updatedPosition.y },
      });

      setBounds(handlePosition);
    }
  };

  const handlePositionDrop = () => {
    if (position.x !== positionOrigin.x || position.y !== positionOrigin.y) {
      const xPosition = position.x - canvasWidth / 2;
      const yPosition = position.y - canvasHeight / 2;

      const updatedOffset = {
        'offset:x': setPrecision(xPosition / canvasWidth, 3),
        'offset:y': setPrecision(-yPosition / canvasHeight, 3),
      };

      setClip({
        ...updatedOffset,
        position: 'center',
      });

      if (isGrabbing && isKeyframesTab) {
        if (activeClipKeyframeId) {
          setKeyframeOffsetX(updatedOffset['offset:x']);
          setKeyframeOffsetY(updatedOffset['offset:y']);
        } else if (AUTO_ADD_KEYFRAME_ENABLED) {
          addClipKeyframe({ props: updatedOffset });
        }
      }
    }

    setIsGrabbing(false);
    setIsMoving(false);
    setSnappingLines({
      x: false,
      y: false,
    });
  };

  const handleResizeGrab = (event) => {
    const mousePosition = event.data.getLocalPosition(spriteRef.current.parent);
    setXResizeOrigin(mousePosition.x);
    setYResizeOrigin(mousePosition.y);
    setPositionOrigin({ ...position });
    setDimensionsOrigin({ ...dimensions });
    setIsResizing(true);
    setIsGrabbing(true);
    setActiveClip(id);
    setActiveTab(getPanelType(calculatedClip['asset:type']));
  };

  const handleResizeDrag = (event, handlePosition) => {
    if (isResizing) {
      const mousePosition = event.data.getLocalPosition(spriteRef.current.parent);

      const { resizedPosition, resizedHeight, resizedWidth } = calculateResize({
        assetHeight,
        assetWidth,
        position: positionOrigin,
        mousePosition,
        handlePosition,
        xResizeOrigin,
        yResizeOrigin,
      });

      const updatedDimensions = {
        ...dimensions,
        height: resizedHeight,
        width: resizedWidth,
      };

      const updatedHandlePosition = calculateHandlePosition({
        dimensions: {
          width: updatedDimensions.width,
          height: updatedDimensions.height,
        },
        position: { x: resizedPosition.x, y: resizedPosition.y },
      });

      setDimensions(updatedDimensions);
      setPosition(resizedPosition);
      setBounds(updatedHandlePosition);
    }
  };

  const handleResizeDrop = () => {
    const xPosition = position.x - canvasWidth / 2;
    const yPosition = position.y - canvasHeight / 2;
    const updatedOffset = {
      'offset:x': setPrecision(xPosition / canvasWidth, 3),
      'offset:y': setPrecision(-yPosition / canvasHeight, 3),
    };

    setClip({
      ...updatedOffset,
      position: 'center',
      'asset:width': setPrecision(dimensions.width, 3),
      'asset:height': setPrecision(dimensions.height, 3),
    });

    if (isGrabbing && isKeyframesTab) {
      if (activeClipKeyframeId) {
        setKeyframeOffsetX(updatedOffset['offset:x']);
        setKeyframeOffsetY(updatedOffset['offset:y']);
      } else {
        addClipKeyframe({ props: updatedOffset });
      }
    }

    setIsGrabbing(false);
    setIsResizing(false);
  };

  const handleScaleGrab = (event) => {
    const mousePosition = event.data.getLocalPosition(spriteRef.current.parent);
    setXScaleOrigin(mousePosition.x);
    setYScaleOrigin(mousePosition.y);
    setDimensionsOrigin({ ...dimensions });
    setScaleOrigin(dimensions.width / assetWidth);
    setIsScaling(true);
    setIsGrabbing(true);
    setActiveClip(id);
    setActiveTab(getPanelType(calculatedClip['asset:type']));
  };

  const handleScaleDrag = (event, currentDragPosition) => {
    if (isScaling) {
      const mousePosition = event.data.getLocalPosition(spriteRef.current.parent);

      const { resizedHeight, resizedScale, resizedWidth } = calculateScale({
        assetHeight,
        assetWidth,
        dimensions,
        mousePosition,
        position: currentDragPosition,
        scaleOrigin,
        dimensionsOrigin,
        xScaleOrigin,
        yScaleOrigin,
        fit,
        canvasHeight,
        canvasWidth,
      });

      const updatedDimensions = {
        ...dimensions,
        height: setPrecision(resizedHeight, 3),
        width: setPrecision(resizedWidth, 3),
      };

      let updatedPosition = { ...position };

      const isShiftAltPressed = event.data.originalEvent.shiftKey && event.data.originalEvent.altKey;

      if (!isShiftAltPressed) {
        // Calculate the true opposite corner and adjust position
        const halfOldWidth = dimensions.width / 2;
        const halfOldHeight = dimensions.height / 2;
        const halfNewWidth = updatedDimensions.width / 2;
        const halfNewHeight = updatedDimensions.height / 2;

        switch (currentDragPosition) {
          case 'topLeft':
            updatedPosition.x += halfOldWidth - halfNewWidth;
            updatedPosition.y += halfOldHeight - halfNewHeight;
            break;
          case 'topRight':
            updatedPosition.x -= halfOldWidth - halfNewWidth;
            updatedPosition.y += halfOldHeight - halfNewHeight;
            break;
          case 'bottomLeft':
            updatedPosition.x += halfOldWidth - halfNewWidth;
            updatedPosition.y -= halfOldHeight - halfNewHeight;
            break;
          case 'bottomRight':
            updatedPosition.x -= halfOldWidth - halfNewWidth;
            updatedPosition.y -= halfOldHeight - halfNewHeight;
            break;
          default:
            // Center drag, no position adjustment needed
            break;
        }
      }

      const handlePosition = calculateHandlePosition({
        dimensions: updatedDimensions,
        position: updatedPosition,
      });

      let adjustedScale = resizedScale / dimensions.aspectRatio;

      if (fit === 'none') {
        adjustedScale = resizedScale;
      }

      setDimensions(updatedDimensions);
      setPosition(updatedPosition);
      setBounds(handlePosition);
      setScale(adjustedScale);
      setDragPosition(currentDragPosition);
    }
  };

  const handleScaleDrop = () => {
    const newScale = setPrecision(scale, 3);
    const xPosition = position.x - canvasWidth / 2;
    const yPosition = position.y - canvasHeight / 2;
    const updatedOffset = {
      'offset:x': setPrecision(xPosition / canvasWidth, 3),
      'offset:y': setPrecision(-yPosition / canvasHeight, 3),
    };

    const clipUpdate = dragPosition === 'center' ? { scale: newScale } : { ...updatedOffset, scale: newScale };

    setClip(clipUpdate);

    if (isGrabbing && isKeyframesTab) {
      if (activeClipKeyframeId) {
        setKeyframeScale(newScale);
        if (dragPosition !== 'center') {
          setKeyframeOffsetX(updatedOffset['offset:x']);
          setKeyframeOffsetY(updatedOffset['offset:y']);
        }
      } else {
        addClipKeyframe({ props: clipUpdate });
      }
    }

    setIsGrabbing(false);
    setIsScaling(false);
    setDragPosition('center');
  };

  const handleRotateGrab = (event) => {
    const mousePosition = event.data.getLocalPosition(spriteRef.current.parent);
    setAngle(angle);
    setAngleOrigin(angle);
    setXScaleOrigin(mousePosition.x);
    setYScaleOrigin(mousePosition.y);
    setDimensionsOrigin({ ...dimensions });
    setIsRotating(true);
    setIsGrabbing(true);
    setActiveClip(id);
    setActiveTab(getPanelType(calculatedClip['asset:type']));
  };

  const handleRotateDrag = (event) => {
    if (isRotating) {
      const mousePosition = event.data.getLocalPosition(spriteRef.current.parent);

      const center = { x: canvasWidth / 2, y: canvasHeight / 2 };
      const size = { width: dimensions.width, height: dimensions.height };
      const canvasSize = { width: canvasWidth, height: canvasHeight };

      const adjustedAngle = calculateAngle({
        mousePosition,
        xScaleOrigin,
        yScaleOrigin,
        angleOrigin,
        canvasHeight,
        canvasWidth,
        position,
      });

      const { angle: snappedAngle, snappingLines: newSnappingLines } = snapPosition(
        position,
        center,
        size,
        canvasSize,
        adjustedAngle
      );

      setAngle(snappedAngle);
      setSnappingLines(newSnappingLines);

      setClip({
        'transform:rotate:angle': parseFloat(snappedAngle),
      });
    }
  };

  const handleRotateDrop = () => {
    const updatedAngle = parseFloat(angle);
    setClip({
      'transform:rotate:angle': updatedAngle,
    });

    if (isGrabbing && isKeyframesTab) {
      if (activeClipKeyframeId) {
        setKeyframeRotate(updatedAngle);
      } else if (AUTO_ADD_KEYFRAME_ENABLED) {
        addClipKeyframe({ props: { ['transform:rotate:angle']: updatedAngle } });
      }
    }

    setAngleOrigin(angle);
    setAngle(angle);
    setIsRotating(false);
    setSnappingLines({
      x: false,
      y: false,
    });
  };

  return {
    bounds,
    canvasWidth,
    canvasHeight,
    dimensions,
    isMoving,
    position,
    scale,
    angle,
    snappingLines,
    calculatedClip,
    handlePositionGrab,
    handlePositionDrop,
    handlePositionDrag,
    handleResizeDrag,
    handleResizeDrop,
    handleResizeGrab,
    handleScaleDrag,
    handleScaleDrop,
    handleScaleGrab,
    handleRotateGrab,
    handleRotateDrag,
    handleRotateDrop,
  };
}

export default useMovableAsset;
