import { Scrollbar } from 'react-scrollbars-custom';
import { useRecoilValue, useRecoilValueLoadable } from 'recoil';

import SettingFont from '@feature/studio/setting/SettingFont';

import ButtonElement from '@components/atoms/ButtonElement';

import { fontListByCategorySelector } from '@store/EditSelectors';
import { useAddClipToTimelineCallback } from '@store/Media';
import { sdkOptionsSelector } from '@store/Sdk';

function PanelTextDefault() {
  const fonts = useRecoilValueLoadable(fontListByCategorySelector);
  const { isSdkEnabled } = useRecoilValue(sdkOptionsSelector);
  const addClipToTimeline = useAddClipToTimelineCallback();

  const handleAddText = (properties) => () => {
    addClipToTimeline({ data: properties, type: 'text' });
  };

  return (
    <div className="workspace__panel-inner">
      <Scrollbar noScrollX style={{ width: '100%', height: '100%' }}>
        <div className="mb-4">Add Text</div>
        <div className="d-flex flex-col gap-2 mb-10">
          <ButtonElement
            variant="light"
            className="panel-button title"
            handleEvent={handleAddText({ text: 'Headline Title', size: 72, width: 800, height: 200 })}
          >
            Headline Title
          </ButtonElement>
          <ButtonElement
            variant="light"
            className="panel-button subtitle"
            handleEvent={handleAddText({ text: 'Subtitle', size: 46 })}
          >
            Subtitle
          </ButtonElement>
          <ButtonElement
            variant="light"
            className="panel-button paragraph"
            handleEvent={handleAddText({ text: 'Paragraph', family: 'Montserrat SemiBold', size: 24 })}
          >
            Paragraph
          </ButtonElement>
        </div>

        {!isSdkEnabled && (
          <div className="mb-10">
            <div className="custom-fonts">
              <h6>My Fonts</h6>
              <SettingFont />
            </div>
            {fonts?.state === 'hasValue' && fonts?.contents?.uploaded.length === 0 && (
              <p className="text-center text-sm text-gray-400 mt-4">Your uploaded fonts will appear here</p>
            )}
            <div className="preset-group">
              {fonts?.state === 'hasValue' &&
                fonts?.contents?.uploaded.map(({ src, family: fontFamily }) => (
                  <ButtonElement
                    key={src}
                    variant="light"
                    className="panel-button"
                    style={{ fontFamily }}
                    handleEvent={handleAddText({ text: fontFamily, family: fontFamily })}
                  >
                    {fontFamily}
                  </ButtonElement>
                ))}
            </div>
          </div>
        )}

        <div>
          <h6>Fonts</h6>
          <div className="preset-group">
            {fonts?.state === 'hasValue' &&
              fonts?.contents?.default.map(({ src, family: fontFamily }) => (
                <ButtonElement
                  key={src}
                  variant="light"
                  className="panel-button"
                  style={{ fontFamily }}
                  handleEvent={handleAddText({ text: fontFamily, family: fontFamily })}
                >
                  {fontFamily}
                </ButtonElement>
              ))}
          </div>
        </div>
      </Scrollbar>
    </div>
  );
}

export default PanelTextDefault;
