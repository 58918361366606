import cn from 'classnames';
import { Col, Row } from 'react-bootstrap';
import { useIntercom } from 'react-use-intercom';
import { useRecoilState, useRecoilValue } from 'recoil';

import { IconDuration, IconInfo, IconStart } from '@assets/icons';

import InputNumber from '@components/controls/InputNumber';
import InputText from '@components/controls/InputText';
import OverrideMenu from '@components/mergeFields/OverrideMenu';

import { clipSelectorFamily, useUpdateClipTimingProperty } from '@store/EditSelectors';
import { sdkOptionsSelector } from '@store/Sdk';

const INTERCOM_ARTICLE_ID = 9531122;

function TimingFields({ id }) {
  const [clip, setClip] = useRecoilState(clipSelectorFamily(id));
  const { isSdkEnabled } = useRecoilValue(sdkOptionsSelector);

  const updateClipTimingProperty = useUpdateClipTimingProperty(id);
  const { showArticle } = useIntercom();

  const handleStartChange = (event) => {
    const { value } = event.target;

    if (value.length > 0 && value >= 0) {
      setClip({ start: parseFloat(value) });
    } else {
      setClip({ start: undefined });
    }
  };

  const handleLengthChange = (event) => {
    const { value } = event.target;

    if (value.length > 0 && value >= 0) {
      setClip({ length: parseFloat(value) });
    } else {
      setClip({ length: undefined });
    }
  };

  const handleInfoClick = () => {
    showArticle(INTERCOM_ARTICLE_ID);
  };

  return (
    <Row>
      <Col md={6}>
        <div className="d-flex align-items-center justify-content-between gap-2">
          {clip?.meta?.start === 'number' ? (
            <InputNumber
              id="start"
              title="Start (seconds)"
              value={clip?.start >= 0 ? clip?.start : ''}
              placeholder={0}
              min={0}
              step={0.01}
              prefix={<IconStart size={14} />}
              handleChange={handleStartChange}
            />
          ) : (
            <InputText
              id="start"
              title="Start (seconds)"
              className="text-uppercase"
              value={clip?.meta?.start}
              prefix={<IconStart size={14} />}
              disabled
            />
          )}
          <OverrideMenu path="start" />
        </div>
        {!isSdkEnabled && (
          <div className="mt-1 d-flex align-items-center justify-content-between">
            {['number', 'auto'].map((type) => (
              <button
                key={type}
                className={cn('timing-button', type, {
                  active: clip?.meta?.start === type,
                })}
                onClick={() => updateClipTimingProperty('start', type)}
              >
                <span>{type === 'number' ? '123' : type.toUpperCase()}</span>
              </button>
            ))}
            <span className="ml-2" onClick={handleInfoClick} style={{ cursor: 'pointer' }}>
              <IconInfo size={14} />
            </span>
          </div>
        )}
      </Col>
      <Col md={6}>
        <div className="d-flex align-items-center justify-content-between gap-2">
          {clip?.meta?.length === 'number' ? (
            <InputNumber
              id="length"
              title="Duration (seconds)"
              value={clip?.length >= 0 ? clip?.length : ''}
              placeholder={0}
              min={0.01}
              step={0.01}
              prefix={<IconDuration size={14} />}
              handleChange={handleLengthChange}
            />
          ) : (
            <InputText
              id="length"
              title="Duration (seconds)"
              className="text-uppercase"
              value={clip?.meta?.length}
              prefix={<IconDuration size={14} />}
              disabled
            />
          )}
          <OverrideMenu path="length" />
        </div>
        {!isSdkEnabled && (
          <div className="mt-1 d-flex align-items-center justify-content-between">
            {['number', 'auto', 'end'].map((type) => (
              <button
                key={type}
                className={cn('timing-button', type, {
                  active: clip?.meta?.length === type,
                })}
                onClick={() => updateClipTimingProperty('length', type)}
              >
                <span>{type === 'number' ? '123' : type.toUpperCase()}</span>
              </button>
            ))}
            <span className="ml-2" onClick={handleInfoClick} style={{ cursor: 'pointer' }}>
              <IconInfo size={14} />
            </span>
          </div>
        )}
      </Col>
    </Row>
  );
}

export default TimingFields;
