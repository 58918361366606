import { useState } from 'react';
import { Card, Tab, Tabs } from 'react-bootstrap';
import { useRecoilState, useRecoilValue } from 'recoil';

import { IconCSV, IconMake, IconZapier } from '@assets/icons';
import LogoEmblem from '@assets/img/logo/emblem.svg?react';

import SettingWebhook from '@feature/studio/setting/SettingWebhook';

import ButtonElement from '@components/atoms/ButtonElement';
import InteractiveConsole from '@components/console/InteractiveConsole';

import { snippetAutomateSelector, snippetTypeAtom } from '@store/Snippet';
import { featureFlagEnabledSelectorFamily } from '@store/UI';

import { snippetTypes } from '@constants/Snippet';

const automateTabs = [
  { key: 'api', label: 'API', icon: LogoEmblem },
  { key: 'csv', label: 'CSV (Bulk)', icon: IconCSV },
  { key: 'zapier', label: 'ZAPIER', icon: IconZapier },
  { key: 'make', label: 'MAKE', icon: IconMake },
];

const ApiTab = () => {
  const snippetStateValue = useRecoilValue(snippetAutomateSelector);
  const [currentType, setSnippetType] = useRecoilState(snippetTypeAtom);

  return (
    <Card>
      <Card.Body>
        <p className="text-lg text-center mt-4 ">
          <span className="font-bold">Send an API request to create a video.</span> <br />
          Poll the API or use a webhook to get notified when the video is ready.
        </p>
        <hr className="mb-8" />
        <div className="flex flex-col gap-5">
          <div>
            <p className="font-bold mb-3">1. Select the payload format</p>
            <div className="flex gap-2">
              {['id', 'blob'].map((slug) => (
                <ButtonElement
                  key={slug}
                  variant={slug === currentType ? 'primary' : 'light'}
                  handleEvent={() => setSnippetType(slug)}
                >
                  {snippetTypes[slug]}
                </ButtonElement>
              ))}
            </div>
          </div>

          <div>
            <p className="font-bold mb-3">2. Set up your webhook callback (optional)</p>
            <SettingWebhook />
          </div>

          <div>
            <p className="font-bold mb-3">3. Send the request</p>
            <InteractiveConsole
              product="edit-api"
              compact
              initialPayload={snippetStateValue}
              initialConfig="editRenderATemplate"
            />
          </div>
        </div>
      </Card.Body>
    </Card>
  );
};

function Automate() {
  const [activeTab, setActiveTab] = useState('api');
  const isAutomateTabsEnabled = useRecoilValue(featureFlagEnabledSelectorFamily('ENABLE_AUTOMATE_TABS'));

  return (
    <div className="max-w-screen-lg mx-auto p-10">
      {isAutomateTabsEnabled && (
        <div className="automate-tab-buttons flex gap-2 mb-4">
          {automateTabs.map(({ key, label, icon: Icon }) => (
            <ButtonElement
              key={key}
              variant={activeTab === key ? 'light' : 'ghost'}
              handleEvent={() => setActiveTab(key)}
            >
              <Icon /> <span>{label}</span>
            </ButtonElement>
          ))}
        </div>
      )}

      <Tabs className="automate-tabs" activeKey={activeTab}>
        <Tab eventKey="api" title="">
          <ApiTab />
        </Tab>
        <Tab eventKey="csv" title="">
          {/* CSV tab content */}
        </Tab>
        <Tab eventKey="zapier" title="">
          {/* Zapier tab content */}
        </Tab>
        <Tab eventKey="make" title="">
          {/* Make tab content */}
        </Tab>
      </Tabs>
    </div>
  );
}

export default Automate;
