import { useEffect, useState } from 'react';

import '@css/Loader.css';

import Logo from '@assets/img/logo/emblem.png';

const movieWords = [
  'Cut Clips',
  'Moving Tracks',
  'Editing Timelines',
  'Rendering at Scale',
  'Automated Campaigns',
  'Transitioning Clips',
  'Magic Effects',
  'Lumas for days',
  'Fade to black',
  'Slide left',
  'Zoom in',
  'Slide right',
  'Zoom out',
  'Overlay it',
  'AI Generated',
  'Snip Snip Snip',
  'Lights Camera Action',
  'Roll the Tape',
  'Fade In Out',
  'Chroma Key Magic',
  'Slow Mo Pro',
  'Quick Cut Master',
  'Transition Tango',
  'Color Grade Guru',
  'Audio Sync Wizard',
];

function Loader() {
  const [position, setPosition] = useState(0);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setPosition((prevPosition) => (prevPosition + 1) % movieWords.length);
    }, 750); // Move to next word every 2 seconds

    return () => clearInterval(intervalId);
  }, []);

  const calculateTranslateY = () => {
    const percentage = (position / movieWords.length) * 100;
    return `translateY(-${percentage}%)`;
  };

  return (
    <div className="loader">
      <div className="spinner">
        <img src={Logo} alt="Shotstack" />
      </div>
      <div className="movie-words-container">
        <div className="movie-words-carousel" style={{ transform: calculateTranslateY() }}>
          {movieWords.map((word, index) => (
            <span key={index} className="movie-word">
              {word}
            </span>
          ))}
        </div>
      </div>
    </div>
  );
}

export default Loader;
